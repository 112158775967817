import React, { useEffect, useState } from 'react';
import classes from './Galaga.module.css'
import galagaLoading from '../../assets/images/galagaLoading.jpg'
import galagaSpinner from '../../assets/images/galagaSpinner.png'
import { useDispatch } from 'react-redux';
import { endGameSession, setGame, startGameSession } from '../../store/gameSlice';
import WebSocketClient from '../../api/wsApi';
import { updateBalance, updateEnergy } from '../../store/userSlice';
import galagaCross from '../../assets/images/galga_cross.png'

const UnityGame = () => {
  const [canvasSize, setCanvasSize] = useState({ width: 800, height: 600 });
  const [restartFlag, setRestartFlag] = useState(false)

  useEffect(() => {
    const updateCanvasSize = () => {
      setCanvasSize({
        width: window.innerWidth,  // 80% от ширины окна
        height: window.innerHeight, // 60% от высоты окна
      });
    };

    // Устанавливаем размеры канваса при изменении размера окна
    window.addEventListener('resize', updateCanvasSize);
    updateCanvasSize(); // Инициализация размеров

    const script = document.createElement('script');
    script.src = "/Games/Galaga/Build/galaga.loader.js";
    script.onload = () => {
      window.createUnityInstance(document.querySelector("#unity-canvas"), {
        dataUrl: "/Games/Galaga/Build/galaga.data.unityweb",
        frameworkUrl: "/Games/Galaga/Build/galaga.framework.js.unityweb",
        codeUrl: "/Games/Galaga/Build/galaga.wasm.unityweb",
        streamingAssetsUrl: "StreamingAssets",
        companyName: "GameDeva",
        productName: "InvadersGalaga",
        productVersion: "1.0",
      }).then((instance) => {
        window.unityInstance = instance; // Сохранение экземпляра Unity для дальнейшего использования
      });
    };
    document.body.appendChild(script);

    return () => {
      window.removeEventListener('resize', updateCanvasSize);
      if (window.unityInstance) {
        window.unityInstance.Quit(); // Завершение работы Unity
      }
    };
  }, [restartFlag]);

  const [isGameLoading, setIsGameLoading] = useState(true);
  const [loadingPercent, setLoadingPercent] = useState(0);
  const [unityMessage, setUnityMessage] = useState('');
  const [isRestart, setIsRestart] = useState(false)
  const [score, setScore] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    let interval;
  
    if (isGameLoading) {
      if (loadingPercent < 90) {
        interval = setInterval(() => {
          setLoadingPercent((prev) => (prev < 90 ? prev + 1 : prev));
        }, 20);
      } else {
        if (unityMessage === 'ready') {
          setIsGameLoading(false)
          setUnityMessage('');
        }
      }
    }
  
    return () => clearInterval(interval);
  }, [isGameLoading, loadingPercent, unityMessage]);

  window.receiveMessageFromUnity = (message) => {
    console.log("Сообщение от Unity:", message);
    setUnityMessage(message)
  };

  function sendEndToUnity(message) {
    if (window.unityInstance) {
      // Unity принимает сообщение в методе ReceiveMessage класса GameProcessor
      window.unityInstance.SendMessage("GameProcessor", "ReceiveMessage", message);
    }
  }

  const endHandler = () => {
    const handleExitResponse = (message) => {
      const msg = JSON.parse(message);
      console.log(msg);
      if (msg.status === "score_updated") {
        dispatch(updateBalance({ balance: msg.new_balance }));
        
        WebSocketClient.sendMessage(JSON.stringify({ command: "stop" }));
    
        dispatch(endGameSession());
        
        WebSocketClient.removeMessageCallback();
      }
    };
    
    WebSocketClient.sendMessage(JSON.stringify({
      command: "update_score",
      score: score,
    }));
    
    WebSocketClient.setOnMessageCallback(handleExitResponse);
  }

  const restartHandler = () => {
    const handleRestartResponse = (message) => {
      const msg = JSON.parse(message);
      console.log(msg);
  
      if (msg.status === "score_updated") {
        dispatch(updateBalance({ balance: msg.new_balance }));

        const handleExitResponse = (message) => {
          const exmsg = JSON.parse(message);
          console.log(exmsg);

          if (exmsg.status === "game_ended") {
          
            const handleStartGameResponse = (message) => {
              const startMsg = JSON.parse(message);
              console.log(startMsg);
            
              if (startMsg.status === 'game_started') {
                dispatch(updateEnergy({ energy: startMsg.energy }));
                setRestartFlag(!restartFlag)
                setLoadingPercent(0);
                setScore(0)
                setIsGameLoading(true);
              } else {
                dispatch(endGameSession());
                dispatch(openEnergyModal());
              }
            
              WebSocketClient.removeMessageCallback(handleStartGameResponse);
            };
            
            WebSocketClient.sendMessage(JSON.stringify({
              command: "start",
              game_id: "duck_hunter"
            }));
            
            WebSocketClient.setOnMessageCallback(handleStartGameResponse);

          } else {
            dispatch(endGameSession());
          }

          WebSocketClient.removeMessageCallback(handleExitResponse);
        }

        WebSocketClient.sendMessage(JSON.stringify({ command: "stop" }));

        WebSocketClient.setOnMessageCallback(handleExitResponse);
  
      } else {
        dispatch(endGameSession());
      }
      
      WebSocketClient.removeMessageCallback(handleRestartResponse);
    };

  
    WebSocketClient.sendMessage(JSON.stringify({
      command: "update_score",
      score: score,
    }));
  
    WebSocketClient.setOnMessageCallback(handleRestartResponse);
  }

  useEffect(() => {
    if (unityMessage.startsWith("end:")) {
      const num = parseInt(unityMessage.slice(5).trim(), 10);
      setScore(num)
      setUnityMessage('')
    } 
  }, [unityMessage])

  const exitHandler = () => {
    console.log(score)
    if (isRestart) {
      restartHandler()
    } else {
      endHandler()
    }
  }

  return (
    <div className={classes.Game}>
      {isGameLoading && 
        <div className={classes.loading}>
          <img src={galagaLoading} alt='galaga' className={classes.galagaLoading}/>
          <img src={galagaSpinner} alt='spinner' className={classes.spinner}/>
        </div>
      }
      {!isGameLoading &&
        <div className={classes.exitButton} onClick={exitHandler}><img src={galagaCross} alt='cross' style={{width: '30px'}}/></div>
      }
      {unityMessage === 'gameover' &&
        <div className={classes.over}>
          <div className={classes.gameOver}>
            <div className={classes.text}>GAME OVER</div>
            <div className={classes.text}>{`Score: ${score}`}</div>
            <div className={classes.buttons}>
              <div className={classes.exitModalButton} onClick={restartHandler}>Restart</div>
              <div className={classes.exitModalButton} onClick={exitHandler}>Exit</div>
            </div>
          </div>
        </div>
      }
      {unityMessage === 'complete' &&
        <div className={classes.over}>
          <div className={classes.gameOver}>
            <div className={classes.text}>Well done!</div>
            <div className={classes.text}>{`Score: ${score}`}</div>
            <div className={classes.buttons}>
              <div className={classes.exitModalButton} onClick={restartHandler}>Restart</div>
              <div className={classes.exitModalButton} onClick={exitHandler}>Exit</div>
            </div>
          </div>
        </div>
      }
      <canvas
        id="unity-canvas"
        width={canvasSize.width}
        height={canvasSize.height}
        style={{ width: '100%'}}
      ></canvas>
    </div>
  );
};

export default UnityGame;
