import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  boughtGames: '',
};

const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    setBoughtGames(state, action) {
      state.boughtGames = action.payload;
    },
  },
});

export const { setBoughtGames } = gamesSlice.actions;
export default gamesSlice.reducer;