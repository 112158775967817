import { combineReducers } from 'redux';
import gameSlice from './gameSlice';
import loadingSlice from './loadingSlice';
import bgSlice from './bgSlice';
import pageSlice from './pageSlice';
import userSlice from './userSlice';
import tasksSlice from './tasksSlice';
import topSlice from './topSlice';
import friendsSlice from './friendsSlice';
import gameToPurchaseSlice from './gameToPurchaseSlice';
import gamesSlice from './gamesSlice';
import walletSlice from './walletSlice';

const appReducer = combineReducers({
  game: gameSlice,
  loading: loadingSlice,
  bg: bgSlice,
  page: pageSlice,
  user: userSlice,
  tasks: tasksSlice,
  top: topSlice,
  friends: friendsSlice,
  gameToPurchase: gameToPurchaseSlice,
  games: gamesSlice,
  wallet: walletSlice
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    // Если действие сброса, устанавливаем состояние на undefined,
    // чтобы все редьюсеры вернулись к своему initialState
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
