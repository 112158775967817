import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  top: [],
  first3: [],
  me: {},
  reward: 0
};

const topSlice = createSlice({
  name: 'top',
  initialState,
  reducers: {
    setTop(state, action) {
      const top = action.payload
      state.top = top
      state.first3 = [...top].slice(0, 3);
    },
    setMe(state, action) {
      state.me = action.payload
    },
    setReward(state, action) {
      state.reward = action.payload
    },
  },
});

export const { setTop, setMe, setReward } = topSlice.actions;
export default topSlice.reducer;