import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: 0,
  telegram_id: 0,
  username: "",
  balance: 0,
  energy: -1,
  energy_friends: 0,
  is_new_user: false,
  created_at: "",
  last_energy_update: "",
  last_login: "",
  balance_tap_weekly: 0,
  is_premium: false,
  login_streak: 0,
  language: ""
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateEnergy: (state, action) => {
      state.energy = action.payload.energy;
    },
    updateBalance: (state, action) => {
      state.balance = action.payload.balance;
    },
    updateEnergyFriends: (state, action) => {
      state.energy_friends = action.payload.energy_friends;
    },
  },
});

export const { setUser, updateEnergy, updateBalance, updateEnergyFriends } = userSlice.actions;
export default userSlice.reducer;