import duck1 from '../assets/spritesForBg/duck_1.png'
import duck2 from '../assets/spritesForBg/duck_2.png'
import duck3 from '../assets/spritesForBg/duck_3.png'
import dog1 from '../assets/spritesForBg/dog_l_1.png'
import dog2 from '../assets/spritesForBg/dog_l_2.png'
import galagaM1 from '../assets/spritesForBg/galaga_m_1.png'
import galagaM2 from '../assets/spritesForBg/galaga_m_2.png'
import galagaS from '../assets/spritesForBg/galaga_s.png'
import pacmanP1 from '../assets/spritesForBg/pacman_p_1.png'
import pacmanP2 from '../assets/spritesForBg/pacman_p_2.png'
import pacmanGR1 from '../assets/spritesForBg/pacman_gr_1.png'
import pacmanGR2 from '../assets/spritesForBg/pacman_gr_2.png'

import React, { act, useEffect } from 'react';
import Phaser from 'phaser';
import { useSelector } from 'react-redux'

const useGameBg = ({ loading, id }) => {
  
  const isGameStarted = useSelector((state) => state.game.isGameStarted)
  const activePage = useSelector((state) => state.page.activePage)

  
  useEffect(() => {
    let game;

    if (!isGameStarted) {

    const GAME_SETTINGS = {
      GRAVITY: { y: 0 },
      DEBUG: false,
    };
  
    const getGameDimensions = () => ({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  
    const config = {
      type: Phaser.AUTO,
      ...getGameDimensions(),
      parent: id,
      scene: {
        preload,
        create,
      },
      physics: {
        default: 'arcade',
        arcade: {
          gravity: GAME_SETTINGS.GRAVITY,
          debug: GAME_SETTINGS.DEBUG,
        },
      },
      render: {
        pixelArt: true,
        antialias: false
      },
      transparent: true
    };
  
    game = new Phaser.Game(config);
    
    function preload() {
      this.load.image('pacman_p1', pacmanP1);
      this.load.image('pacman_p2', pacmanP2);
      this.load.image('pacman_gr1', pacmanGR1);
      this.load.image('pacman_gr2', pacmanGR2);
      this.load.image('galaga_s', galagaS);
      this.load.image('galaga_m1', galagaM1);
      this.load.image('galaga_m2', galagaM2);
      this.load.image('duck1', duck1);
      this.load.image('duck2', duck2);
      this.load.image('duck3', duck3);
      this.load.image('dog1', dog1);
      this.load.image('dog2', dog2);

    }
  
    function create() {
      createAnimations.call(this);
      if (loading) {

        spawnDog.call(this)

        this.time.addEvent({
          delay: 500,
          callback: spawnDuck,
          callbackScope: this,
        });

        this.time.addEvent({
          delay: 500,
          callback: spawnGalagaPair,
          callbackScope: this,
        });

        this.time.addEvent({
          delay: 500,
          callback: spawnPacmanPair,
          callbackScope: this,
        });

        this.time.addEvent({
          delay: 3000,
          callback: spawnDuck,
          callbackScope: this,
          loop: true
        });

        this.time.addEvent({
          delay: 4000,
          callback: spawnGalagaPair,
          callbackScope: this,
          loop: true
        });

        this.time.addEvent({
          delay: 5000,
          callback: spawnPacmanPair,
          callbackScope: this,
          loop: true
        });
        
      } else {
        this.time.addEvent({
          delay: 500,
          callback: spawnRandomEntity,
          callbackScope: this,
        });

        this.time.addEvent({
          delay: 5000,
          callback: spawnRandomEntity,
          callbackScope: this,
          loop: true,
        });
      }
    }
  
    function createAnimations() {
      this.anims.create({
        key: 'duck',
        frames: [
          {key: 'duck1'},
          {key: 'duck2'},
          {key: 'duck3'},
        ],
        frameRate: 5,
        repeat: -1,
      });
      this.anims.create({
        key: 'pacmanP',
        frames: [
          {key: 'pacman_p1'},
          {key: 'pacman_p2'},
        ],
        frameRate: 5,
        repeat: -1,
      });
      this.anims.create({
        key: 'pacmanGR',
        frames: [
          {key: 'pacman_gr1'},
          {key: 'pacman_gr2'},
        ],
        frameRate: 5,
        repeat: -1,
      });
      this.anims.create({
        key: 'galagaM',
        frames: [
          {key: 'galaga_m1'},
          {key: 'galaga_m2'},
        ],
        frameRate: 5,
        repeat: -1,
      });
      this.anims.create({
        key: 'dogL',
        frames: [
          {key: 'dog1'},
          {key: 'dog2'},
        ],
        frameRate: 5,
        repeat: -1,
      });
    }

    function spawnDog() {
      const dog = this.physics.add.sprite(
        this.game.config.width / 2, // Позиция по X (по центру)
        this.game.config.height - 200, // Позиция по Y
        'dog1' // Используем первую картинку для спавна
      );

      dog.setScale(7); // Увеличиваем размер собаки
      dog.setDepth(10)
      dog.play('dogL'); // Запускаем анимацию смеха собаки

      dog.alpha = 0; // Начальная прозрачность 0 (полностью прозрачный)

  // Создаем Tween для увеличения прозрачности
      this.tweens.add({
        targets: dog,
        alpha: 1, // Конечная прозрачность (полностью видимый)
        duration: 1000, // Длительность анимации в миллисекундах
        ease: 'Power2', // Тип сглаживания
      });
    }

    function spawnDuck() {
      const side = Math.random() < 0.5 ? 'left' : 'right'; // Выбор стороны (левая или правая)
      const duck = this.physics.add.sprite(
        side === 'left' ? -100 : this.game.config.width + 100, // Начальная позиция X
        Phaser.Math.Between(100, this.game.config.height - 100), // Случайная позиция по Y
        'duck1'
      );
      
      if (side === 'left') {
        duck.setFlipX(false)
      } else {
        duck.setFlipX(true)
      }
  
      duck.setScale(1.5)
      duck.play('duck');
      duck.setVelocityX(side === 'left' ? 200 : -200);
      
      duck.checkWorldBounds = true;
      duck.outOfBoundsKill = true;
    }
  
    function spawnGalagaPair() {
      // Выбираем направление: 0 — слева направо, 1 — справа налево, 2 — сверху вниз, 3 — снизу вверх
      const direction = Phaser.Math.Between(0, 3);
  
      let x1, y1, x2, y2, velocityX, velocityY, angle;
  
      // Определяем стартовые позиции и скорости в зависимости от направления
      if (direction === 0) { // Слева направо
        x1 = -100;
        y1 = Phaser.Math.Between(100, this.game.config.height - 100);
        x2 = x1 - 200; // Второй персонаж на 100 пикселей позади
        y2 = y1;
        velocityX = 200;
        velocityY = 0;
        angle = 90;
      } else if (direction === 1) { // Справа налево
        x1 = this.game.config.width + 100;
        y1 = Phaser.Math.Between(100, this.game.config.height - 100);
        x2 = x1 + 200; 
        y2 = y1;
        velocityX = -200;
        velocityY = 0;
        angle = -90;
      } else if (direction === 2) { // Сверху вниз
        x1 = Phaser.Math.Between(100, this.game.config.width - 100);
        y1 = -100;
        x2 = x1;
        y2 = y1 - 200;
        velocityX = 0;
        velocityY = 200;
        angle = 180;
      } else { // Снизу вверх
        x1 = Phaser.Math.Between(100, this.game.config.width - 100);
        y1 = this.game.config.height + 100;
        x2 = x1;
        y2 = y1 + 200;
        velocityX = 0;
        velocityY = -200;
      }
  
      // Создаем первого персонажа
      const galagaS = this.physics.add.sprite(x2, y2, 'galaga_s');
      galagaS.setVelocity(velocityX, velocityY);
      galagaS.setAngle(angle);
  
      // Создаем второго персонажа с анимацией, летящего следом
      const galagaM = this.physics.add.sprite(x1, y1, 'galaga_m1');
      galagaM.play('galagaM');
      galagaM.setVelocity(velocityX, velocityY);
      galagaM.setAngle(angle);
  
      galagaS.checkWorldBounds = true;
      galagaS.outOfBoundsKill = true;
      galagaM.checkWorldBounds = true;
      galagaM.outOfBoundsKill = true;
    }
  
    function spawnPacmanPair() {
      // Выбираем направление: 0 — слева направо, 1 — справа налево, 2 — сверху вниз, 3 — снизу вверх
      const direction = Phaser.Math.Between(0, 3);
  
      let x1, y1, x2, y2, velocityX, velocityY, angle;
  
      // Определяем стартовые позиции и скорости в зависимости от направления
      if (direction === 0) { // Слева направо
        x1 = -100;
        y1 = Phaser.Math.Between(100, this.game.config.height - 100);
        x2 = x1 - 200; // Второй персонаж на 100 пикселей позади
        y2 = y1;
        velocityX = 200;
        velocityY = 0;
      } else if (direction === 1) { // Справа налево
        x1 = this.game.config.width + 100;
        y1 = Phaser.Math.Between(100, this.game.config.height - 100);
        x2 = x1 + 200; 
        y2 = y1;
        velocityX = -200;
        velocityY = 0;
        angle = 180;
      } else if (direction === 2) { // Сверху вниз
        x1 = Phaser.Math.Between(100, this.game.config.width - 100);
        y1 = - 100;
        x2 = x1;
        y2 = y1 - 200;
        velocityX = 0;
        velocityY = 200;
        angle = 90;
      } else { // Снизу вверх
        x1 = Phaser.Math.Between(100, this.game.config.width - 100);
        y1 = this.game.config.height + 100;
        x2 = x1;
        y2 = y1 + 200;
        velocityX = 0;
        velocityY = -200;
        angle = -90
      }
  
      // Создаем первого персонажа
      const pacmanG = this.physics.add.sprite(x2, y2, 'pacman_gr1');
      pacmanG.play('pacmanGR');
      pacmanG.setVelocity(velocityX, velocityY);
  
      // Создаем второго персонажа с анимацией, летящего следом
      const pacmanP = this.physics.add.sprite(x1, y1, 'pacman_p1');
      pacmanP.play('pacmanP');
      pacmanP.setVelocity(velocityX, velocityY);
      pacmanP.setAngle(angle);
  
      pacmanP.checkWorldBounds = true;
      pacmanP.outOfBoundsKill = true;
      pacmanG.checkWorldBounds = true;
      pacmanG.outOfBoundsKill = true;
    }
  
    function spawnRandomEntity() {
      const randomChoice = Phaser.Math.Between(0, 2); // Генерируем случайное число 0 или 1
      if (randomChoice === 0) {
        spawnDuck.call(this);
      } else if (randomChoice === 1) {
        spawnGalagaPair.call(this);
      } else {
        spawnPacmanPair.call(this)
      }
    }
  
    const resizeGame = () => {
      if (game) {
        game.scale.resize(window.innerWidth, window.innerHeight);
      }
    };

    window.addEventListener('resize', resizeGame);

    return () => {
      window.removeEventListener('resize', resizeGame);
      if (game) {
        game.destroy(true);
        game = null;
      }
    };
  }
}, [loading, id, isGameStarted, activePage]);

}

export default useGameBg;
