import { useDispatch, useSelector } from 'react-redux';

import classes from './GameTile.module.css'
import cartridge from '../../../assets/images/cartridge.png'
import tonLogo from '../../../assets/images/tonLogo.png'
import star from '../../../assets/images/tgstar.png'
import { openBuyModal } from '../../../store/pageSlice'
import { setSelectedGameToPurchase } from '../../../store/gameToPurchaseSlice';
import { confirmPayment } from '../../../api/paymentApi';

const GameTile = ({ state, gameImg, title, currency, price, gameId }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.page.token)

  const BuyHandler = () => {
    dispatch(setSelectedGameToPurchase({ gameImg, title, currency, price, gameId }))
    dispatch(openBuyModal())
  }

  const checkHandler = async () => {
    const res = await confirmPayment(gameId, token)
    if (res) {
      console.log(res)
    }
  }

  if (state === 'release') {
    return (
      <div className={classes.GameTile}>
        <div className={classes.cartridge}>
          <img src={cartridge} alt='cartridge' className={classes.box}/>
          <img src={gameImg} alt='game' className={classes.label}/>
        </div>
        <div className={classes.title}>{title}</div>
        <div className={classes.rButton}>Play</div>
      </div>
    );
  } else if (state === 'notBought') {
    return (
        <div className={classes.GameTile}>
          <div className={classes.cartridge}>
            <div className={classes.shadow}>
              <div className={classes.price}>
                {currency === 'star' ?
                  <img src={star} alt='star' className={classes.star}/>
                  :
                  <img src={tonLogo} alt='ton' className={classes.ton}/>
                }
                <div className={classes.value}>{price}</div>
              </div>
            </div>
            <img src={cartridge} alt='cartridge' className={classes.box}/>
            <img src={gameImg} alt='game' className={classes.label}/>
          </div>
          <div className={classes.title}>{title}</div>
        <div className={classes.bButton} onClick={BuyHandler}>Buy</div>
      </div>
    );
  } else if (state === 'pending') {
    return (
      <div className={classes.GameTile}>
        <div className={classes.cartridge}>
          <div className={classes.shadow}></div>
          <img src={cartridge} alt='cartridge' className={classes.box}/>
          <img src={gameImg} alt='game' className={classes.label}/>
        </div>
        <div className={classes.title}>{title}</div>
        <div className={classes.sButton} onClick={() => checkHandler()}>Check</div>
      </div>
    );
  }else if (state === 'soon') {
    return (
      <div className={classes.GameTile}>
        <div className={classes.cartridge}>
          <div className={classes.shadow}></div>
          <img src={cartridge} alt='cartridge' className={classes.box}/>
          <img src={gameImg} alt='game' className={classes.label}/>
        </div>
        <div className={classes.title}>{title}</div>
        <div className={classes.sButton}>Soon</div>
      </div>
    );
  } else {
    return (
      <div className={classes.GameTile}>
        <div className={classes.cartridge}>
          <div className={classes.shadow}></div>
          <img src={cartridge} alt='cartridge' className={classes.box}/>
        </div>
        <div className={classes.title}>{title}</div>
        <div className={classes.sButton}>Soon</div>
      </div>
    );
  }
}

export default GameTile;