import { useDispatch, useSelector } from 'react-redux';
import classes from './InviteModal.module.css'
import { closeInviteModal } from '../../../store/pageSlice';
import { useState } from 'react';

const InviteModal = () => {
  const isInviteModalOpen = useSelector((state) => state.page.isInviteModalOpen)
  const link = useSelector((state) => state.friends.link)
  const [copyMessage, setCopyMessage] = useState('');
  const dispatch = useDispatch()

  const closeHandler = () => {
    dispatch(closeInviteModal())
  }

  if (!isInviteModalOpen) return null;

  const sendInvite = () => {
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    const text = 
    `\n🎮👾 Hey! I'm playing retro games on Retro Bit and earning rewards!💰✨\nJoin me and start earning too! 🚀\n\nSign up with my link: ${link}\n\n#RetroBit #PlayAndEarn`;

    tg.openTelegramLink(`https://t.me/share/url?url=${link}&text=${encodeURIComponent(text)}`);
  }

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    setCopyMessage('Copyed to clipboard'); // Установить сообщение
    setTimeout(() => {
      setCopyMessage(''); // Очистить сообщение через 2 секунды
    }, 2000);
  }
  
  return (
    <div className={classes.modalOverlay} onClick={closeHandler}>
      {copyMessage && <div className={classes.copyMessage}>{copyMessage}</div>}
      <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={classes.button} onClick={sendInvite}>Send invite</div>
        <div className={classes.button} onClick={copyLink}>Copy link</div>
        <div className={classes.closeButton} onClick={closeHandler}>Close</div>
      </div>
    </div>
  );
};

export default InviteModal;