import { useState } from 'react';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { initiatePurchase } from '../../../api/paymentApi'
import { closeBuyModal } from '../../../store/pageSlice';
import { setSelectedGameToPurchase } from '../../../store/gameToPurchaseSlice';
import Spinner from '../../ui/Spinner/Spinner'

import cartridge from '../../../assets/images/cartridge.png'
import tonLogo from '../../../assets/images/tonLogo.png'
import star from '../../../assets/images/tgstar.png'
import paidIcon from '../../../assets/images/paid.png'
import classes from './BuyModal.module.css'

const BuyModal = () => {
  const dispatch = useDispatch()
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const isBuyModalOpen = useSelector((state) => state.page.isBuyModalOpen)
  const selectedGameToPurchase = useSelector((state) => state.gameToPurchase.selectedGameToPurchase)
  const token = useSelector((state) => state.page.token)

  const closeHandler = () => {
    dispatch(closeBuyModal())
    dispatch(setSelectedGameToPurchase(null))
  }

  if (!isBuyModalOpen) return null;

  const handlePayment = async () => {
    try {
      setIsPaymentLoading(true);
  
      const tg = window.Telegram ? window.Telegram.WebApp : null;
      if (!tg) {
        console.error("Telegram WebApp API не доступен");
        setIsPaymentLoading(false);
        return;
      }
  
      const res = await initiatePurchase(selectedGameToPurchase.gameId, token);
  
      tg.onEvent("payment_closed", (event) => {
        if (event.success) {
          setIsPaid(true);
          console.log("Оплата завершена успешно!");
        } else {
          console.error("Оплата не завершена");
        }

        setIsPaymentLoading(false);
    
        tg.offEvent("payment_closed");
      });
    
      tg.openTelegramLink(res.result);
    } catch (error) {
      console.error("Ошибка при инициации оплаты:", error);
      setIsPaymentLoading(false);
    }
  };
  
  return (
    <div className={classes.modalOverlay}>
      <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={classes.title}>{selectedGameToPurchase.title}</div>
        <div className={classes.cartridge}>
          <img src={cartridge} alt='cartridge' className={classes.box}/>
          <img src={selectedGameToPurchase.gameImg} alt='game' className={classes.label}/>
        </div>
        <div className={classes.buttonWrap}>
          <div className={classes.button} onClick={handlePayment}>
            {isPaymentLoading && (
              <div className={classes.spinnerContainer}>
                <Spinner width={'30px'} height={'30px'} b={'4px solid #f3f3f3'} bt={'4px solid #3498db'}/>
              </div>
            )}
            {isPaid && (
              <div className={classes.buttonContentWrap}>
                Paid
                <img src={paidIcon} alt='paidIcon' className={classes.icon}/>
              </div>
            )}
            {!isPaid && !isPaymentLoading && (
              <div className={classes.buttonContentWrap}>
                Buy for
                <div className={classes.price}>
                  {selectedGameToPurchase.currency === 'star' ?
                    <img src={star} alt='star' className={classes.icon}/>
                      :
                      <img src={tonLogo} alt='ton' className={classes.icon}/>
                  }                      
                  <div className={classes.value}>{selectedGameToPurchase.price}</div>
                </div>
              </div>
            )}
          </div>
          <div className={classes.closeButton} onClick={closeHandler}>Close</div>
        </div>
      </div>
    </div>
  );
};

export default BuyModal;