import { useDispatch, useSelector } from 'react-redux';
import classes from './EnergyModal.module.css'
import { closeEnergyModal, openRewardModal, setActivePage, setRewardValue } from '../../../store/pageSlice';
import energy from '../../../assets/images/main_energy.png'
import { useAdsgram } from '../../../ads/useAdsgram';
import { useCallback, useState } from 'react';
import { updateEnergy } from '../../../store/userSlice';
import qbert from '../../../assets/images/qbert.png'

const EnergyModal = () => {
  const isEnergyModalOpen = useSelector((state) => state.page.isEnergyModalOpen)
  const buttonsState = useSelector((state) => state.tasks.buttonsState)
  const AdController =  window.AdsModulePgram;
  const user = useSelector((state) => state.user)

  const dispatch = useDispatch()
  const [isAd, setIsAd] = useState(false)
  const closeHandler = () => {
    dispatch(closeEnergyModal())
  }

  const onReward = useCallback(() => {
    console.log('reward')
    dispatch(updateEnergy({energy: +user.energy + 5}))
    closeHandler()
    dispatch(setRewardValue(5))
    dispatch(openRewardModal())
  }, []);

  const showAd = () => {
    setIsAd(true)
      AdController.show({ AppId: "4100415" }).then((result) => {
        onReward();
        console.log(result)
      }).catch((error) => {
        console.log(error); // Logged error in the console
      });
  }

  const checkTasks = () => {
    const isAllCompleted = buttonsState.every(obj => obj.status === 'completed');
    return isAllCompleted
  }

  const redirectToEarn = () => {
    dispatch(setActivePage(2))
  }


  if (!isEnergyModalOpen) return null;

  return (
    <div className={classes.modalOverlay} onClick={!isAd ? closeHandler : () => {}}>
      <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={classes.content}>
          <div className={classes.title}>Not enough energy <img src={qbert} alt='qbert' className={classes.qbert}/></div>
          {checkTasks() ? 
            <div className={classes.watchButton} onClick={!isAd ? showAd : () => {}}>Watch Ads</div>
          :
            <div className={classes.watchButton} onClick={redirectToEarn}>Earn</div>
          }
          <div className={classes.toGet}>
            <div className={classes.title}>To get {checkTasks() && `5`}</div>
            <img src={energy} alt='energy' className={classes.energy}/>
          </div>
          <div className={classes.closeButton} onClick={!isAd ? closeHandler : () => {}}>Close</div>
        </div>
      </div>
    </div>
  );
};

export default EnergyModal;