import { createSlice } from '@reduxjs/toolkit';
import Home from '../components/pages/Home/Home';

const initialState = {
  isUserLoaded: false,
  isTopLoaded: false,
  activePage: 0,
  isEnergyModalOpen: false,
  isTasksModalOpen: false,
  currentTasks: [],
  taskImg: '',
  taskTitle: '',
  isInviteModalOpen: false,
  isRewardModalOpen: false,
  isBuyModalOpen: false,
  rewardValue: 0,
  token: '',
  key: 0,
  error: false,
};

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setActivePage(state, action) {
      state.activePage = action.payload;
    },
    openEnergyModal(state) {
      state.isEnergyModalOpen = true;
    },
    closeEnergyModal(state) {
      state.isEnergyModalOpen = false;
    },
    openTasksModal(state, action) {
      state.isTasksModalOpen = true;
      state.currentTasks = action.payload.links
      state.taskImg = action.payload.img
      state.taskTitle = action.payload.title
    },
    closeTasksModal(state) {
      state.isTasksModalOpen = false;
    },
    openInviteModal(state) {
      state.isInviteModalOpen = true;
    },
    closeInviteModal(state) {
      state.isInviteModalOpen = false;
    },
    openRewardModal(state) {
      state.isRewardModalOpen = true;
    },
    closeRewardModal(state) {
      state.isRewardModalOpen = false;
    },
    openBuyModal(state) {
      state.isBuyModalOpen = true;
    },
    closeBuyModal(state) {
      state.isBuyModalOpen = false;
    },
    setRewardValue(state, action) {
      state.rewardValue = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    setIsUserLoaded(state) {
      state.isUserLoaded = true;
    },
    setIsTopLoaded(state) {
      state.isTopLoaded = true;
    },
    setKey(state) {
      state.key = Date.now();
    },
    showError(state) {
      state.error = true
    },
    hideError(state) {
      state.error = false
    },
  },
});

export const { setActivePage, openEnergyModal, closeEnergyModal, openInviteModal, closeInviteModal, openTasksModal, closeTasksModal, openBuyModal, closeBuyModal, openRewardModal, closeRewardModal, setRewardValue, setToken, setIsUserLoaded, setIsTopLoaded, setKey, showError, hideError } = pageSlice.actions;
export default pageSlice.reducer;