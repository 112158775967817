import { useTonConnectUI, useTonWallet, useTonAddress } from '@tonconnect/ui-react';
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ScrollableList from '../../ui/ScrollableList/SlrollableList'
import GameTile from '../../ui/GameTile/GemeTile'
import tonLogo from '../../../assets/images/tonLogo.png'
import backArrow from '../../../assets/images/backArrow.png'
import { formatNumber } from '../../../utils/formatBalance'
import { setIsWalletConnected } from '../../../store/walletSlice'
import BuyModal from '../../modals/BuyModal/BuyModal'
import { sendWalletAdress } from '../../../api/walletApi'

import classes from './Collection.module.css'
import dhIcon from '../../../assets/images/dhicon.png'
import pmIcon from '../../../assets/images/pmicon.png'
import glIcon from '../../../assets/images/glicon.png'
import duck_i from '../../../assets/images/gameIcons/duck_i.png'
import ice_i from '../../../assets/images/gameIcons/ice_i.png'
import loo_i from '../../../assets/images/gameIcons/loo_i.png'
import qbert_i from '../../../assets/images/gameIcons/qbert_i.png'
import sky_i from '../../../assets/images/gameIcons/sky_i.png'
import tetris_i from '../../../assets/images/gameIcons/tetris_i.png'

const icons = {
  galaga: glIcon,
  pacman: pmIcon,
  duck_hunter: dhIcon
};

const Collection = () => {
  const dispatch = useDispatch();

  const isBuyModalOpen = useSelector((state) => state.page.isBuyModalOpen);
  const token = useSelector((state) => state.page.token)
  const boughtGames = useSelector((state) => state.games.boughtGames)

  const findGame = (gameId) => {
    if (boughtGames[0]) {
      return boughtGames.find(game => game.game_id === gameId);
    }
  }

  const getStatus = (purchased) => {
    if (purchased === 'true') {
      return 'released'
    } else {
      if (purchased === 'pending') {
        return 'pending'
      } else {
        return 'notBought'
      }
    }
  }

  return (
    <div className={classes.MyGames}>
      <div className={classes.gameBg} id='game-bg'></div>

      {isBuyModalOpen && <BuyModal/>}
      <div className={classes.titleMain}>
        Games
      </div>
      <ScrollableList height={true} noGap>
        <div className={classes.row}>
          <div className={classes.item} style={{border: 'none', paddingBottom: '10px', paddingRight: '10px'}}>
            <GameTile 
              state={'release'} 
              gameImg={icons[findGame('duck_hunter')?.game_id]} 
              title={findGame('duck_hunter')?.title} 
              gameId={findGame('duck_hunter')?.game_id} 
            />
          </div>
          <div className={classes.item} style={{borderTop: 'none', borderBottom: 'none' , padding: '0 10px', paddingBottom: '10px'}}>
            <GameTile 
              state={getStatus(findGame('galaga')?.purchased)} 
              gameImg={icons[findGame('galaga')?.game_id]} 
              title={findGame('galaga')?.title} 
              gameId={findGame('galaga')?.game_id} 
              currency={'star'} 
              price={findGame('galaga')?.price_stars}
            />
          </div>
          <div className={classes.item} style={{border: 'none', paddingBottom: '10px', paddingLeft: '10px'}}>
            <GameTile 
              state={'soon'} 
              gameImg={icons[findGame('pacman')?.game_id]} 
              title={findGame('pacman')?.title} 
              gameId={findGame('pacman')?.game_id} 
              currency={'star'} 
              price={findGame('pacman')?.price_stars}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.item} style={{borderLeft: 'none', borderRight: 'none', padding: '10px 0', paddingRight: '10px'}}>
            <GameTile 
              state={'soon'} 
              gameImg={sky_i}
              title={findGame('sky_destroyer')?.title} 
              gameId={findGame('sky_destroyer')?.game_id} 
              currency={'star'} 
              price={findGame('sky_destroyer')?.price_stars}
            />
          </div>
          <div className={classes.item} style={{padding: '10px 10px'}}>
            <GameTile 
              state={'soon'} 
              gameImg={tetris_i}
              title={findGame('tetris')?.title} 
              gameId={findGame('tetris')?.game_id} 
              currency={'star'} 
              price={findGame('tetris')?.price_stars}
            />
          </div>
          <div className={classes.item} style={{borderLeft: 'none', borderRight: 'none', padding: '10px 0', paddingLeft: '10px'}}>
            <GameTile 
              state={'soon'} 
              gameImg={loo_i}
              title={findGame('looney_tunes')?.title} 
              gameId={findGame('looney_tunes')?.game_id} 
              currency={'star'} 
              price={findGame('looney_tunes')?.price_stars}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.item} style={{border: 'none', paddingTop: '10px', paddingRight: '10px'}}>
            <GameTile 
              state={'soon'} 
              gameImg={ice_i}
              title={findGame('ice_climber')?.title} 
              gameId={findGame('ice_climber')?.game_id} 
              currency={'star'} 
              price={findGame('ice_climber')?.price_stars}
            />
          </div>
          <div className={classes.item} style={{borderTop: 'none', borderBottom: 'none' , padding: '0 10px', paddingTop: '10px'}}>
            <GameTile 
              state={'soon'} 
              gameImg={duck_i}
              title={findGame('duck_tales')?.title} 
              gameId={findGame('duck_tales')?.game_id} 
              currency={'star'} 
              price={findGame('duck_tales')?.price_stars}
            />
          </div>
          <div className={classes.item} style={{border: 'none', paddingTop: '10px', paddingLeft: '10px'}}>
            <GameTile 
              state={'soon'} 
              gameImg={qbert_i}
              title={findGame('qbert')?.title} 
              gameId={findGame('qbert')?.game_id} 
              currency={'star'} 
              price={findGame('qbert')?.price_stars}
            />
          </div>
        </div>
      </ScrollableList>
    </div>
  )
}

export default Collection;
