import classes from './Earn.module.css'
import scrooge from '../../../assets/images/earn_scrooge.png'
import tgIcon from '../../../assets/images/earn_tg.png'
import coin from '../../../assets/images/main_coin.png'
import dhbg from '../../../assets/images/main_dh_bg.png'
import ScrollableList from '../../ui/ScrollableList/SlrollableList'
import userImg from '../../../assets/images/main_player.png'
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux'
import TasksModal from '../../modals/TasksModal/TasksModal'
import { openRewardModal, openTasksModal, setRewardValue } from '../../../store/pageSlice'
import { useCallback, useEffect, useState } from 'react'
import { checkLinkTask, checkTask, getTasks, goLink, socialCheck } from '../../../api/taskApi'
import energy from '../../../assets/images/main_energy.png'
import { updateButtonState } from '../../../store/tasksSlice'
import { updateEnergy } from '../../../store/userSlice'
import retroboy from '../../../assets/images/retr.png'
import taskGreen from '../../../assets/images/taskGreen.png'

import done from '../../../assets/images/done.png'
import { setReward } from '../../../store/topSlice'
import RewardModal from '../../modals/RewardModal/RewardModal'

const rightAnim = {
  initial: { x: 20, opacity: 0 },
  animate: { 
    x: [20, 0],
    opacity: [0, 1],
    transition: { duration: 1, ease: 'easeInOut' }
  },
};

const leftAnim = {
  initial: { x: -20, opacity: 0 },
  animate: { 
    x: [-20, 0],
    opacity: [0, 1], 
    transition: { duration: 1, ease: 'easeInOut' }
  },
};

const botAnim = {
  initial: { y: 20, opacity: 0 },
  animate: { 
    y: [20, 0],
    opacity: [0, 1], 
    transition: { duration: 1, ease: 'easeInOut' }
  },
};

const TaskTileSmall = ({img, name, subContent, onClick, button}) => {
  
  return (
    <div className={classes.gameTile_small}>
      <div className={classes.gameLeft}>
        <img src={img} alt='userImg' className={classes.gameImg_small}/>
        <div className={classes.gameInfo_small}>
          <div className={classes.gameTitle_small}>{name}</div>
          <div className={classes.subContent}>
            +{subContent}
            <img src={energy} alt='enrg' className={classes.smallEnergy}/>
          </div>
        </div>
      </div>
      <div className={classes.playButton_small} style={{opacity: button === 'Done' ? '50%' : '100%'}} onClick={onClick}>
        {button}
      </div>
    </div>
  )
}

const TaskTileBig = ({img, title, cost, onClick}) => {
  return (
    <div className={classes.gameTile_big}>
      <img src={img} alt='game' className={classes.bgImg}/>
      <div className={classes.gradient}></div>
      <div className={classes.gameInfo}>
        <div className={classes.gameTitle_big}>{title}</div>
      </div>
      <div className={classes.playButton} onClick={onClick}>Open</div>
    </div>
  )
}

const Earn = () => {

  const user = useSelector((state) => state.user)
  const isRewardModalOpen = useSelector((state) => state.page.isRewardModalOpen)
  const retrobitTasks = useSelector((state) => state.tasks.retrobitTasks)
  const storyTasks = useSelector((state) => state.tasks.storyTasks)
  const commonTasks = useSelector((state) => state.tasks.commonTasks)
  const weeklyTasks = useSelector((state) => state.tasks.weeklyTasks)
  const link = useSelector((state) => state.friends.link)

  const buttonsState = useSelector((state) => state.tasks.buttonsState)

  const token = useSelector((state) => state.page.token)
  const isTasksModalOpen = useSelector((state) => state.page.isTasksModalOpen)
  const dispatch = useDispatch()

  const specTasksHandler = (links, img, title) => {
    dispatch(openTasksModal({links: links, img: img, title: title}))
  }

  const retrobitTaskHandler = async (id) => {
    const currentState = buttonsState.find(item => item.id === id);
    const currentTask = retrobitTasks.find(item => item.id === id);
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    if (currentTask.task_type === 'subscribe') {
      
      //start
      if (currentState.status === 'not_completed') {
        tg.openTelegramLink(currentTask.link);
        dispatch(updateButtonState({ id: id, status: 'in_progress'}))
      }
      //check
      if (currentState.status === 'in_progress') {
        const response = await checkTask(id, token);
        if (response[0]?.status === 'completed') {
          dispatch(updateEnergy({energy: response[0].new_energy}))
          dispatch(setRewardValue(currentTask.reward))
          dispatch(openRewardModal())
          dispatch(updateButtonState({ id: id, status: 'completed'}))
        } else {
          tg.openTelegramLink(currentTask.link);
        }
      }
      //done
      if (currentState.status === 'completed') {
        tg.openTelegramLink(currentTask.link);
      }
    
    }
  }

  const storyTaskHandler = async (id) => {
    const currentState = buttonsState.find(item => item.id === id);
    const currentTask = storyTasks.find(item => item.id === id);
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    if (currentState.status === 'not_completed') {
      tg.shareToStory(currentTask.link, {text: `${currentTask.description}\n ${link}`, widget_link: {url: link, name: currentTask.title}})
      const response = await checkLinkTask(id, token)
      if (response[0]?.status === 'completed') {
        setTimeout(() => {
          dispatch(updateEnergy({energy: response[0].new_energy}))
          dispatch(setRewardValue(currentTask.reward))
          dispatch(openRewardModal())
          dispatch(updateButtonState({ id: id, status: 'completed'}))
        }, 5000)
      }
    } else {
      tg.shareToStory(currentTask.link, {text: `${currentTask.description}\n ${link}`, widget_link: {url: link, name: currentTask.title}})
    }
  }

  const commonTaskHandler = async (id) => {
    const currentState = buttonsState.find(item => item.id === id);
    const currentTask = commonTasks.find(item => item.id === id);
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    if (currentTask.task_type === 'subscribe') {
      
      //start
      if (currentState.status === 'not_completed') {
        tg.openTelegramLink(currentTask.link);
        dispatch(updateButtonState({ id: id, status: 'in_progress'}))
      }
      //check
      if (currentState.status === 'in_progress') {
        const response = await checkTask(id, token);
        if (response[0]?.status === 'completed') {
          dispatch(updateEnergy({energy: response[0].new_energy}))
          dispatch(setRewardValue(currentTask.reward))
          dispatch(openRewardModal())
          dispatch(updateButtonState({ id: id, status: 'completed'}))
        } else {
          tg.openTelegramLink(currentTask.link);
        }
      }
      //done
      if (currentState.status === 'completed') {
        tg.openTelegramLink(currentTask.link);
      }
    
    } else {
      
      if (currentState.status === 'not_completed') {
        tg.openTelegramLink(currentTask.link);
        const response = await checkLinkTask(id, token)
        if (response[0]?.status === 'completed') {
          setTimeout(() => {
            dispatch(updateEnergy({energy: response[0].new_energy}))
            dispatch(setRewardValue(currentTask.reward))
            dispatch(openRewardModal())
            dispatch(updateButtonState({ id: id, status: 'completed'}))
          }, 5000)
        }
      } else {
        tg.openTelegramLink(currentTask.link);
      }
    }
  }

  const buttonContent = (id) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим текущее состояние кнопки
  
    switch (currentState?.status) {
      case 'not_completed':
        return 'Start';
      case 'in_progress':
        return 'Check';
      case 'completed':
        return 'Done';
      default:
        return 'Unknown';
    }
  };

  return (
    <div className={classes.Invite}>
      {isTasksModalOpen && <TasksModal/>}
      {isRewardModalOpen && <RewardModal/>}
      <div className={classes.gameBg} id='game-bg'></div>
      
      <div className={classes.carousel} onTouchMove={(e) => e.stopPropagation()}>
        {retrobitTasks[0] &&
          <div className={classes.claimSection}>
            <div className={classes.info}>
              <div className={classes.subtg}>Subscribe to<br/>Retro Bit</div>
              <div className={classes.rewardtg}>+{retrobitTasks[0].reward} <img src={energy} alt='enrg' className={classes.energyIcon}/></div>
            </div>
            <img src={retroboy} alt='tg' className={classes.tgIcon}/>
            <div className={classes.tgButton} style={{opacity: buttonContent(retrobitTasks[0].id) === 'Done' ? '50%' : '100%'}} onClick={() => retrobitTaskHandler(retrobitTasks[0].id)}>{buttonContent(retrobitTasks[0].id)}</div>
          </div>
        }
        {storyTasks[0] &&
          <div className={classes.claimSection}>
            <div className={classes.info}>
              <div className={classes.subtg}>{storyTasks[0].title}</div>
              <div className={classes.rewardtg}>+{storyTasks[0].reward} <img src={energy} alt='enrg' className={classes.energyIcon}/></div>
            </div>
            <img src={taskGreen} alt='tg' className={classes.tgIcon}/>
            <div className={classes.tgButton} style={{opacity: buttonContent(storyTasks[0].id) === 'Done' ? '50%' : '100%'}} onClick={() => storyTaskHandler(storyTasks[0].id)}>{buttonContent(storyTasks[0].id)}</div>
          </div>
        }
      </div>

      <div className={classes.carousel2} onTouchMove={(e) => e.stopPropagation()}>
        {weeklyTasks[0] && weeklyTasks.map((task) => (
          <TaskTileBig subTasks={task.link} key={task.id} img={task.image_url} title={task.title} onClick={() => specTasksHandler(task.link, task.image_url, task.title)}/>
        )) 
        }
      </div>

      <ScrollableList height={true}>
        {commonTasks.map((task) => (
          <>
          <TaskTileSmall 
            img={task.image_url} 
            name={task.title} 
            subContent={task.reward} 
            onClick={() => commonTaskHandler(task.id)} 
            button={buttonContent(task.id)}
          />
          <div className={classes.hr}></div>
          </>
        ))}
        <div className={classes.empty}></div>
      </ScrollableList>
    </div>
  )
}

export default Earn;

/*
 
        
 */