import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedGameToPurchase: null,
};

const gameToPurchaseSlice = createSlice({
  name: 'gameToPurchase',
  initialState,
  reducers: {
    setSelectedGameToPurchase(state, action) {
      state.selectedGameToPurchase = action.payload;
    },
  },
});

export const { setSelectedGameToPurchase } = gameToPurchaseSlice.actions;
export default gameToPurchaseSlice.reducer;