import classes from './Loading.module.css'
import stars from '../../../assets/images/loading_stars.png'
import moon from '../../../assets/images/loading_moon.png'
import useGameBg from '../../../games/useGameBg'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Loading = () => {

  const percent = useSelector((state) => state.loading.percent)

  return (
    <div className={classes.Loading}>
      <img src={stars} alt='stars' className={classes.stars}/>
      <img src={moon} alt='moon' className={classes.moon}/>
      <div id='game-bg-loading' className={classes.gameBg}></div>
      <div className={classes.loadSection}>
        <div className={classes.text}>Loading...</div>
        <div className={classes.loadingBar}>
          <div className={classes.loadingPercent} style={{width: `${percent}%`}}></div>
        </div>
      </div>
    </div>
  )
}

export default Loading;