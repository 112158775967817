import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';

import * as Sentry from "@sentry/react";
import NoPhone from './components/pages/NoPhone/NoPhone';

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  //True, если мобильное
};

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  //True, если поддерживает тапы
};

const tg = window.Telegram ? window.Telegram.WebApp : null;
const platform = window.Telegram.WebApp.platform;
console.log(platform)

if (tg) {
  tg.expand();
  tg.ready();
  tg.isClosingConfirmationEnabled = true; // Включаем подтверждение перед закрытием
}

const data = window.Telegram.WebApp.initData;
const params = new URLSearchParams(data);
const user = JSON.parse(params.get('user')).id;
console.log(user)

const test = window.Telegram.WebApp;
console.log(test)

const allowPc = () => {
  if (user === 1032198799 || user === 746919752 || user === 6456833420 || user === 2066993629) {
    return true;
  }

  const isMobilePlatform = platform === 'android' || platform === 'ios';
  if (isMobilePlatform && isMobileDevice() && isTouchDevice()) {
    return true;
  }

  return false;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WebAppProvider>
      <Provider store={store}>
      {true ? //Заменить на allowPc()
        <App />
        :
        <NoPhone/>
      }
      </Provider>
    </WebAppProvider>
  </React.StrictMode>
);