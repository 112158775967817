import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isWalletConnected: false,
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setIsWalletConnected(state, action) {
      state.isWalletConnected = action.payload;
    },
  },
});

export const { setIsWalletConnected } = walletSlice.actions;
export default walletSlice.reducer;