import classes from './Top.module.css'
import kong from '../../../assets/images/top_kong.png'
import coin from '../../../assets/images/main_coin.png'
import ScrollableList from '../../ui/ScrollableList/SlrollableList'
import userImg from '../../../assets/images/main_player.png'
import romario from '../../../assets/images/romario.png'
import alexandrio from '../../../assets/images/alxandrio.png'

import { motion } from 'framer-motion';
import { useSelector } from 'react-redux'
import { formatNumber } from '../../../utils/formatBalance'

const rightAnim = {
  initial: { x: 20, opacity: 0 },
  animate: { 
    x: [20, 0],
    opacity: [0, 1],
    transition: { duration: 1, ease: 'easeInOut' }
  },
};

const leftAnim = {
  initial: { x: -20, opacity: 0 },
  animate: { 
    x: [-20, 0],
    opacity: [0, 1], 
    transition: { duration: 1, ease: 'easeInOut' }
  },
};

const botAnim = {
  initial: { y: 20, opacity: 0 },
  animate: { 
    y: [20, 0],
    opacity: [0, 1], 
    transition: { duration: 1, ease: 'easeInOut' }
  },
};


const UserTile = ({img, name, balance, place}) => {
  return (
    <div className={classes.gameTile_small}>
      <div className={classes.gameLeft}>
        <div className={classes.place} style={{border: `1px solid ${place === '1Up' ? '#FFFB00' : place === '2Up' ? '#DADADA' : place === '3Up' ? '#D24900' : '#FFFFFF'}`}}>{place}</div>
        <img src={img} alt='userImg' className={classes.gameImg_small}/>
        <div className={classes.gameInfo_small}>
          <div className={classes.gameTitle_small}>{name}</div>
        </div>
      </div>
      <div className={classes.right}>
        <img src={coin} alt='coin' className={classes.userCoin}/>
        <div className={classes.userBalance}>{formatNumber(balance)}<span className={classes.week}>/week</span></div>
      </div>
    </div>
  )
}

const Top = () => {

  const top = useSelector((state) => state.top.top)
  const me = useSelector((state) => state.top.me)
  const user = useSelector((state) => state.user)

  return (
    <div className={classes.Invite}>
      <div className={classes.gameBg} id='game-bg'></div>
      <div className={classes.title}>
        <img src={kong} alt='icon' className={classes.icon}/>
        <div className={classes.text}>
          Top players<br/>this week 
        </div>
      </div>
      <motion.div className={classes.claimSection}
        initial="initial"
        animate="animate"
        variants={rightAnim}
      >
        <UserTile img={me.profile_photo_url ? me.profile_photo_url : romario} name={user.username} balance={me.balance_tap_weekly} place={me.position}/>
      </motion.div>
      
      <ScrollableList label={'Top 20'} height={true}>
        {top.map((user) => (
          <>
            <UserTile img={user.profile_photo_url ? user.profile_photo_url : alexandrio} name={user.username} balance={user.balance_tap_weekly} place={user.position}/>
            <div className={classes.hr}></div>
          </>
        ))}
      </ScrollableList>
    </div>
  )
}

export default Top;