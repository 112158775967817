import classes from './Home.module.css'
import coin from '../../../assets/images/main_coin.png'
import energy from '../../../assets/images/main_energy.png'
import dhbg from '../../../assets/images/main_dh_bg.png'
import gift from '../../../assets/images/main_gift.png'
import star from '../../../assets/images/main_star.png'
import top3 from '../../../assets/images/main_top3.png'
import player from '../../../assets/images/main_player.png'
import dhIcon from '../../../assets/images/dhicon.png'
import pmIcon from '../../../assets/images/pmicon.png'
import glIcon from '../../../assets/images/glicon.png'
import galagaBack from '../../../assets/images/galagaBack.jpg'


import { useDispatch, useSelector } from 'react-redux'
import { setGame, setSetLastPlayed, startGameSession, toggleGame } from '../../../store/gameSlice'
import Game from '../../../games/DuckHunt/Game'
import useGameBg from '../../../games/useGameBg';
import { motion } from 'framer-motion';

import React, { useCallback, useEffect, useState } from 'react';
import ScrollableList from '../../ui/ScrollableList/SlrollableList'
import { useAdsgram } from '../../../ads/useAdsgram'
import EnergyModal from '../../modals/EnergyModal/EnergyModal'
import { openEnergyModal, setActivePage } from '../../../store/pageSlice'
import { formatNumber } from '../../../utils/formatBalance'
import WebSocketClient from '../../../api/wsApi'
import { updateEnergy } from '../../../store/userSlice'
import alexandrio from '../../../assets/images/alxandrio.png'
import RewardModal from '../../modals/RewardModal/RewardModal'

const rightAnim = {
  initial: { x: 20, opacity: 0 },
  animate: { 
    x: [20, 0],
    opacity: [0, 1],
    transition: { duration: 1, ease: 'easeInOut' }
  },
};

const leftAnim = {
  initial: { x: -20, opacity: 0 },
  animate: { 
    x: [-20, 0],
    opacity: [0, 1], 
    transition: { duration: 1, ease: 'easeInOut' }
  },
};

const GameTileBig = ({img, title, cost, onClick}) => {
  return (
    <div className={classes.gameTile_big}>
      <img src={img} alt='game' className={classes.bgImg}/>
      <div className={classes.gradient}></div>
      <div className={classes.gameInfo}>
        <div className={classes.gameTitle_big}>{title}</div>
        <div className={classes.gameCost_big}>
          <img src={energy} alt='energy' className={classes.gameEnergy_big}/>
          <div className={classes.gameValue_big}>-{cost}</div>
          <div className={classes.gameText_big}>for start</div>
        </div>
      </div>
      <div className={classes.playButton} onClick={onClick}>Play</div>
    </div>
  )
}

const GameTileSmall = ({img, title, subtitle, onClick, release, purchased}) => {
  return (
    <div className={classes.gameTile_small}>
      <div className={classes.gameLeft}>
        <img src={img} alt='game' className={classes.gameImg_small}/>
        <div className={classes.gameInfo_small}>
          <div className={classes.gameTitle_small}>{title}</div>
          <div className={classes.gameCost_small}>
            {release && 
              <img src={energy} alt='energy' className={classes.gameEnergy_small}/>
            }
            <div className={classes.gameValue_small}>{subtitle}</div>
            {release && 
              <div className={classes.gameText_small}>for start</div>
            }
          </div>
        </div>
      </div>
      {release ?
        purchased === 'true' ?
        <div className={classes.playButton_small} onClick={onClick}>Play</div>
        : 
        <div className={classes.playButton_small_b} onClick={onClick}>Buy</div>
      :
        <div className={classes.playButton_small} style={{opacity: '50%'}} onClick={() => {}}>Play</div> 
      }
    </div>
  )
}

const TopPlayer = ({img, name, place}) => {
  return (
    <div className={classes.t3Player}>
      <img src={img} alt='player' className={classes.t3PlayerImg}/>
      <div className={classes.t3PlayerStats}>
        <div className={classes.t3PlayerName}>{name}</div>
        <div className={classes.stars}>
          {place === 1 && <>
            <img src={star} alt='star' className={classes.star}/>
            <img src={star} alt='star' className={classes.star}/>
            <img src={star} alt='star' className={classes.star}/>
            <img src={star} alt='star' className={classes.star}/>
            <img src={star} alt='star' className={classes.star}/>
          </>}
          {place === 2 && <>
            <img src={star} alt='star' className={classes.star}/>
            <img src={star} alt='star' className={classes.star}/>
            <img src={star} alt='star' className={classes.star}/>
            <img src={star} alt='star' className={classes.star}/>
          </>}
          {place === 3 && <>
            <img src={star} alt='star' className={classes.star}/>
            <img src={star} alt='star' className={classes.star}/>
            <img src={star} alt='star' className={classes.star}/>
          </>}
        </div>
      </div>
    </div>
  )
}



const Home = () => {
  const user = useSelector((state) => state.user)
  const weeklyReward = useSelector((state) => state.top.reward)
  const first3 = useSelector((state) => state.top.first3)
  const lastPlayed = useSelector((state) => state.game.lastPlayed)
  const isLoading = useSelector((state) => state.loading.isLoading)
  const isEnergyModalOpen = useSelector((state) => state.page.isEnergyModalOpen)
  const isRewardModalOpen = useSelector((state) => state.page.isRewardModalOpen)
  const boughtGames = useSelector((state) => state.games.boughtGames)

  const dispatch = useDispatch()
  const [playAnimation, setPlayAnimation] = useState(false);

  useEffect(() => {
    if (user.energy !== -1 && user.energy < 5) {
      dispatch(openEnergyModal())
    } 
  }, [user.energy])
  
  useEffect(() => {
    if(!isLoading) {
      setPlayAnimation(true)
    }
  }, [isLoading])

  const playDuckHunt = () => {
    dispatch(setGame(0))
    const handleGameStartResponse = (message) => {
      const msg = JSON.parse(message);
      console.log(msg);
  
      if (msg.status === 'game_started') {
        dispatch(updateEnergy({ energy: msg.energy }));
        dispatch(startGameSession());
        dispatch(setSetLastPlayed('duck_hunter'))
      } else {
        dispatch(openEnergyModal());
      }
  
      WebSocketClient.removeMessageCallback(handleGameStartResponse);
    };
  
    WebSocketClient.sendMessage(JSON.stringify({
      command: "start",
      game_id: "duck_hunter"
    }));
  
    WebSocketClient.setOnMessageCallback(handleGameStartResponse);
  };

  const playGalaga = () => {
    dispatch(setGame(1))
    const handleGameStartResponse = (message) => {
      const msg = JSON.parse(message);
      console.log(msg);
  
      if (msg.status === 'game_started') {
        dispatch(updateEnergy({ energy: msg.energy }));
        dispatch(startGameSession());
        dispatch(setSetLastPlayed('galaga'))
      } else {
        dispatch(openEnergyModal());
      }
  
      WebSocketClient.removeMessageCallback(handleGameStartResponse);
    };
  
    WebSocketClient.sendMessage(JSON.stringify({
      command: "start",
      game_id: "galaga"
    }));
  
    WebSocketClient.setOnMessageCallback(handleGameStartResponse);
  }

  const findGame = (gameId) => {
    if (boughtGames[0]) {
      return boughtGames?.find(game => game.game_id === gameId);
    }
  }

  const checkGame = (gameId) => {
    if (findGame(gameId)?.purchased === 'release') {
      playGalaga()
    } else {
      dispatch(setActivePage(2))
    }
  }
  
  return (
    <div className={classes.Home}>
      {isEnergyModalOpen && <EnergyModal/>}
      {isRewardModalOpen && <RewardModal/>}
      <div className={classes.gameBg} id='game-bg'></div>
      <motion.div
        initial="initial"
        animate={playAnimation ? "animate" : "initial"}
        variants={rightAnim}
      >
      <div className={classes.infoSection}>
        <div className={classes.left}>
          <div className={classes.weeklyReward}>
            <img src={gift} alt='gift' className={classes.gift}/>
            <div className={classes.wrCaption}>
              <div className={classes.wrTitle}>Weekly reward</div>
              <div className={classes.wrAmount}>{weeklyReward} TON</div>
            </div>
          </div>
          <div className={classes.stats}>
            <div className={classes.balance}>
              <img src={coin} alt='coin' className={classes.coin}/>
              <div className={classes.value}>{formatNumber(user.balance)}</div>
            </div>
            <div className={classes.energy}>
              <img src={energy} alt='coin' className={classes.energyIcon}/>
              <div className={classes.value}>{user.energy}</div>
            </div>
          </div>
        </div>
        <div className={classes.top3}>
          <div className={classes.t3Title}>
            <img src={top3} alt='top3' className={classes.t3Img}/>
            <div className={classes.t3Text}>Top 3 players</div>
          </div>
          <div className={classes.t3Players}>
            <TopPlayer img={first3[0]?.profile_photo_url ? first3[0]?.profile_photo_url : alexandrio} name={first3[0]?.username} place={1}/>
            <TopPlayer img={first3[1]?.profile_photo_url ? first3[1]?.profile_photo_url : alexandrio} name={first3[1]?.username} place={2}/>
            <TopPlayer img={first3[2]?.profile_photo_url ? first3[2]?.profile_photo_url : alexandrio} name={first3[2]?.username} place={3}/>
          </div>
        </div>
      </div>
      </motion.div>
        <div className={classes.lastPlayed}>
          <div className={classes.title}>Last played</div>
          <motion.div
            initial="initial"
            animate={playAnimation ? "animate" : "initial"}
            variants={leftAnim}
          >
          {lastPlayed === 'duck_hunter' &&
            <GameTileBig img={dhbg} title={'Duckhunt'} cost={5} onClick={playDuckHunt}/>
          }
          {lastPlayed === 'galaga' &&
            <GameTileBig img={galagaBack} title={'Galaga'} cost={10} onClick={playGalaga}/>
          }
          </motion.div>
        </div>
        <ScrollableList label={'All games'}>
          <GameTileSmall release purchased={'true'} img={dhIcon} title={'DuckHunt'} subtitle={-5} onClick={playDuckHunt}/>
          <div className={classes.hr}></div>
          <GameTileSmall release purchased={findGame('galaga')?.purchased} img={glIcon} title={'Galaga'} subtitle={-10} onClick={() => checkGame('galaga')}/>
          <div className={classes.hr}></div>
          <GameTileSmall img={pmIcon} title={'Pacman'} subtitle={'Coming soon'} onClick={() => {}}/>
        </ScrollableList>
      </div>
  )
}

export default Home;