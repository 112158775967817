import axios from "axios";
import { decode } from "./userApi";
import { path } from "./path";

export const getBouhgtGames = async (token) => {
  try {
    const response = await axios.get(
      `${path}/games/${decode(token)}`, 
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка получения купленных игр', err);
    
    return null;
  }
};