import { useEffect, useState } from 'react';
import classes from './App.module.css'
import Home from './components/pages/Home/Home';
import Menu from './components/ui/Menu/Menu';
import Game from './games/DuckHunt/Game';
import { useDispatch, useSelector } from 'react-redux';
import Loading from './components/pages/Loading/Loading';
import { incrementPercent, playLoading, stopLoading, toggleLoading } from './store/loadingSlice';
import useGameBg from './games/useGameBg';
import { setGame } from './store/gameSlice';
import { setBg } from './store/bgSlice';
import Invite from './components/pages/Invite/Invite';
import Earn from './components/pages/Earn/Earn';
import Top from './components/pages/Top/Top';
import { decode, getToken, getUser } from './api/userApi';
import { setIsTopLoaded, setIsUserLoaded, setToken } from './store/pageSlice';
import { getTasks } from './api/taskApi';
import { setUser } from './store/userSlice';
import { setTasks } from './store/tasksSlice';
import { getTop } from './api/leagueApi';
import { getFriends, getLink } from './api/friendsApi';
import { getBouhgtGames } from './api/gameApi';
import { setMe, setReward, setTop } from './store/topSlice';
import { setFriends, setIsFriendsLoaded, setLink } from './store/friendsSlice';
import { setBoughtGames } from './store/gamesSlice';
import WebSocketClient from './api/wsApi';
import Collection from './components/pages/Collection/Collection';
import Galaga from './games/Galaga/Galaga';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { confirmPayment } from './api/paymentApi';

function App() {

  const pages = [
    <Home/>,
    <Invite/>,
    <Collection/>,
    <Earn/>,
    <Top/>,
  ]

  const games = [
    <Game/>,
    <Galaga/>
  ]

  const key = useSelector((state) => state.page.key)

  const bg = useSelector((state) => state.bg)
  const isGameStarted = useSelector((state) => state.game.isGameStarted)
  const game = useSelector((state) => state.game.game)
  const isLoading = useSelector((state) => state.loading.isLoading)
  const activePage = useSelector((state) => state.page.activePage)
  const token = useSelector((state) => state.page.token)
  const isUserLoaded = useSelector((state) => state.page.isUserLoaded)
  const isTopLoaded = useSelector((state) => state.page.isTopLoaded)
  const isErrorRestart = useSelector((state) => state.page.error)
  const [isError, setIsError] = useState(false)

  const dispatch = useDispatch()

  useGameBg({ loading: bg.loading, id: bg.id });

  useEffect(() => {
    document.addEventListener('touchmove', function(event) {
      event.preventDefault();
    }, { passive: false });

    return () => {
      document.removeEventListener('touchmove', function(event) {
        event.preventDefault();
      }, { passive: false });
    };
  }, [key]);

  useEffect(() => {
    const fetchUser = async (token) => {
      try {
        const response = await getUser(token);
        dispatch(setUser(response));
        dispatch(setIsUserLoaded());
      } catch (error) {
        setIsError(true);
      }
    };

    const fetchTasks = async (token) => {
      try {
        const response = await getTasks(token);
        dispatch(setTasks(response));
      } catch (error) {
        setIsError(true);
      }
    };

    const fetchTop = async (token) => {
      try {
        const response = await getTop(token);
        dispatch(setTop(response.top_20_users));
        dispatch(setMe(response.user_position));
        dispatch(setReward(response.weekly_reward_toncoin));
        dispatch(setIsTopLoaded());
      } catch (error) {
        setIsError(true);
      }
    };

    const fetchLink = async (token) => {
      try {
        const response = await getLink(token);
        dispatch(setLink(response.invite_link));
      } catch (error) {
        setIsError(true);
      }
    };

    const fetchGames = async (token) => {
      try {
        const response = await getBouhgtGames(token);
        console.log(response);
        dispatch(setBoughtGames(response))
      } catch (error) {
        setIsError(true);
      }
    };

    const fetchAllData = async () => {
      try {
        const response = await getToken();
        const token = response.access_token;
        dispatch(setToken(token));
        if (token) {
          await Promise.all([
            fetchUser(token),
            fetchTasks(token),
            fetchTop(token),
            fetchLink(token),
            fetchGames(token),
          ]);
        }

      } catch (error) {
        setIsError(true);
      }
    };

    fetchAllData();

    
  }, [key]);

  useEffect(() => {
    if(token) {
      WebSocketClient.init(`wss://tap.gekkoapp.online/ws/${decode(token)}/${token}`);
      return () => {
        WebSocketClient.close();
      };
    }
  }, [token, key]);

  useEffect(() => {
    dispatch(playLoading())
    dispatch(incrementPercent(100, 1, 50))
    setTimeout(() => {
      dispatch(stopLoading())
      dispatch(setBg({ loading: false, id: 'game-bg'}))
    }, 5000)
  }, [key])

  return (
    <div className={classes.App} key={key}>
        {isErrorRestart && <div className={classes.copyMessage}>Connection error. App will be restarted</div>}
        {isGameStarted && games[game]}
        {(isLoading || isError || !isUserLoaded || !isTopLoaded) && <Loading/>}
        {!isGameStarted && (
        <>
          {pages[activePage]}
          <Menu />
        </>
        )}
    </div>
  );
}

export default App;


