import classes from './Menu.module.css'
import gameImg from '../../../assets/images/menu_game.png'
import inviteImg from '../../../assets/images/menu_invite.png'
import earnImg from '../../../assets/images/menu_earn.png'
import topImg from '../../../assets/images/menu_top.png'
import homeImg from '../../../assets/images/menu_home.png'
import { useDispatch, useSelector } from 'react-redux'
import { setActivePage } from '../../../store/pageSlice'
import Home from '../../pages/Home/Home'
import Invite from '../../pages/Invite/Invite'
import Top from '../../pages/Top/Top'
import Earn from '../../pages/Earn/Earn'
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react'


const MenuItem = ({ img, caption, onClick, isActive }) => {

  return (
    <motion.div
      className={classes.MenuItem}
      onClick={onClick}
      whileTap={{ scale: 1.2 }} // Масштаб увеличивается до 1.2 при нажатии
      transition={{ type: 'spring', stiffness: 300, damping: 20 }}
    >
      <img src={img} alt={caption} className={classes.menuImg} />
      <div className={classes.menuCaption} style={{opacity: isActive ? '1' : '0.6'}}>{caption}</div>
    </motion.div>
  );
};

const Menu = () => {

  const activePage = useSelector((state) => state.page.activePage)
  const dispatch = useDispatch()

  return (
    <div className={classes.Menu}>
      <MenuItem img={homeImg} caption={'Home'} onClick={() => dispatch(setActivePage(0))} isActive={activePage === 0}/>
      <MenuItem img={inviteImg} caption={'Invite'} onClick={() => dispatch(setActivePage(1))} isActive={activePage === 1}/>
      <MenuItem img={gameImg} caption={'Games'} onClick={() => dispatch(setActivePage(2))} isActive={activePage === 2}/>
      <MenuItem img={earnImg} caption={'Earn'} onClick={() => dispatch(setActivePage(3))} isActive={activePage === 3}/>
      <MenuItem img={topImg} caption={'Top'} onClick={() => dispatch(setActivePage(4))} isActive={activePage === 4}/>
    </div>
  )
}

export default Menu;