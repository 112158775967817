import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  percent: 0,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    playLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },
    setPercent(state, action) {
      state.percent = action.payload
    },
  },
});

export const incrementPercent = (targetPercent, step = 1, delay = 100) => (dispatch, getState) => {
  const { percent } = getState().loading;
  let currentPercent = percent;

  const interval = setInterval(() => {
    if (currentPercent >= targetPercent) {
      clearInterval(interval);
    } else {
      currentPercent = Math.min(currentPercent + step, targetPercent);
      dispatch(loadingSlice.actions.setPercent(currentPercent));
    }
  }, delay);
};

export const { playLoading, stopLoading } = loadingSlice.actions;
export default loadingSlice.reducer;