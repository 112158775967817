import classes from './Invite.module.css'
import mario from '../../../assets/images/invite_mario.png'
import coin from '../../../assets/images/main_coin.png'
import ScrollableList from '../../ui/ScrollableList/SlrollableList'
import userImg from '../../../assets/images/alxandrio.png'
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux'
import InviteModal from '../../modals/InviteModal/InviteModal'
import { openInviteModal, openRewardModal, setRewardValue } from '../../../store/pageSlice'
import { claimEnergy, getFriends } from '../../../api/friendsApi'
import { updateEnergy, updateEnergyFriends } from '../../../store/userSlice'
import energyImg from '../../../assets/images/main_energy.png'
import { formatNumber } from '../../../utils/formatBalance'
import { setReward } from '../../../store/topSlice'
import Spinner from '../../ui/Spinner/Spinner'
import React, { useEffect } from 'react'
import { setFriends, setIsFriendsLoaded } from '../../../store/friendsSlice'

const rightAnim = {
  initial: { x: 20, opacity: 0 },
  animate: { 
    x: [20, 0],
    opacity: [0, 1],
    transition: { duration: 1, ease: 'easeInOut' }
  },
};

const leftAnim = {
  initial: { x: -20, opacity: 0 },
  animate: { 
    x: [-20, 0],
    opacity: [0, 1], 
    transition: { duration: 1, ease: 'easeInOut' }
  },
};

const botAnim = {
  initial: { y: 20, opacity: 0 },
  animate: { 
    y: [20, 0],
    opacity: [0, 1], 
    transition: { duration: 1, ease: 'easeInOut' }
  },
};


const UserTile = ({img, name, subContent, balance}) => {
  return (
    <div className={classes.gameTile_small}>
      <div className={classes.gameLeft}>
        <img src={img} alt='userImg' className={classes.gameImg_small}/>
        <div className={classes.gameInfo_small}>
          <div className={classes.gameTitle_small}>{name}</div>
          <div className={classes.subContent}>+{subContent}<img src={energyImg} alt='enrg' className={classes.energyImg}/></div>
        </div>
      </div>
      <div className={classes.right}>
        <img src={coin} alt='coin' className={classes.userCoin}/>
        <div className={classes.userBalance}>{formatNumber(balance)}</div>
      </div>
    </div>
  )
}

const Invite = () => {

  const user = useSelector((state) => state.user)
  const token = useSelector((state) => state.page.token)
  const isInviteModalOpen = useSelector((state) => state.page.isInviteModalOpen)
  const friends = useSelector((state) => state.friends.friends)
  const premium = useSelector((state) => state.friends.premium)
  const isFriendsLoaded = useSelector((state) => state.friends.isFriendsLoaded)

  console.log(friends)
  const dispatch = useDispatch()

  const inviteHandler = () => {
    dispatch(openInviteModal())
  }

  const claimFriendsEnergy = async () => {
    if (user.energy_friends) {
      const response = await claimEnergy(token)
      if (response.new_total_energy) {
        dispatch(updateEnergy({energy: response.new_total_energy}))
        dispatch(setRewardValue(user.energy_friends))
        dispatch(openRewardModal())
        dispatch(updateEnergyFriends({ energy_friends: 0 }))
      }
    }
  }

  useEffect(() => {
    if (!isFriendsLoaded) {
      const fetchFriends = async (token) => {
        try {
          const response = await getFriends(token)
          if (response && response.referrals) {
            dispatch(setFriends(response.referrals));
            dispatch(setIsFriendsLoaded());
          }
        } catch (error) {

        }
      }
  
      fetchFriends(token)
    }
  }, [isFriendsLoaded, token, dispatch])

  return (
    <div className={classes.Invite}>
      {isInviteModalOpen && <InviteModal/>}
      <div className={classes.gameBg} id='game-bg'></div>
      <div className={classes.title}>
        <img src={mario} alt='icon' className={classes.icon}/>
        <div className={classes.text}>
          Your friends
        </div>
      </div>
      <motion.div className={classes.claimSection}
        initial="initial"
        animate="animate"
        variants={rightAnim}
      >
        <div className={classes.claim}>
          <img src={energyImg} alt='enrg' className={classes.claimEnergy}/>
          <div className={classes.claimValue}><span className={classes.fontFix}>_</span>{user.energy_friends}</div>
        </div>
        <div className={classes.claimButton} style={{opacity: user.energy_friends ? '100%' : '50%'}} onClick={claimFriendsEnergy}>Claim</div>
      </motion.div>
      <motion.div className={classes.inviteText}
        initial="initial"
        animate="animate"
        variants={leftAnim}
      >
        Get +5 energy for each regular user<br/>And +15 for each premium user
      </motion.div>
      <ScrollableList label={<div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>Friends: {isFriendsLoaded ? `${friends?.length}, Premium: ${premium?.length}` : <Spinner width={'10px'} height={'10px'} b={'1.6px solid #f3f3f3'} bt={'1.6px solid #3498db'} margin={0}/>}</div>} height={true}>
      {isFriendsLoaded ? (
        friends[0] ? (
          friends.map((user) => (
            <React.Fragment key={user.id}>
              <UserTile
                img={user.profile_photo_url ? user.profile_photo_url : userImg}
                subContent={user.earnings}
                name={user.username}
                balance={user.balance}
              />
              <div className={classes.hr}></div>
            </React.Fragment>
          ))
        ) : (
          <div className={classes.noFriends}>You haven't invited friends yet</div>
        )
      ) : (
        <div className={classes.spinnerContainer}>
          <Spinner/>
        </div>
      )}        
        <div className={classes.empty}></div>
      </ScrollableList>
      <motion.div className={classes.inviteButton}
        initial="initial"
        animate="animate"
        variants={botAnim}
        onClick={inviteHandler}
      >
        <span className={classes.fontFix2}>_</span>Invite a friend
      </motion.div>
    </div>
  )
}

export default Invite