import { useDispatch, useSelector } from 'react-redux';
import classes from './RewardModal.module.css'
import energy from '../../../assets/images/main_energy.png'
import { closeRewardModal } from '../../../store/pageSlice';

const RewardModal = () => {
  const isRewardModalOpen = useSelector((state) => state.page.isRewardModalOpen)
  const rewardValue = useSelector((state) => state.page.rewardValue)

  const dispatch = useDispatch()

  const closeHandler = () => {
    dispatch(closeRewardModal())
  }

  if (!isRewardModalOpen) return null;

  return (
    <div className={classes.modalOverlay} onClick={closeHandler}>
      <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={classes.text}>
          Gratz! You have received:
        </div>
        <div className={classes.reward}>
          +{rewardValue}
          <img src={energy} alt='enrg' className={classes.img}/>
        </div>
        <div className={classes.close} onClick={closeHandler}>
          Great!
        </div>
      </div>
    </div>
  );
};

export default RewardModal;