import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isGameStarted: false,
  game: '',
  lastPlayed: 'duck_hunter',
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setGame(state, action) {
      state.game = action.payload;
    },
    startGameSession(state) {
      state.isGameStarted = true
    },
    endGameSession(state) {
      state.isGameStarted = false
    },
    setSetLastPlayed(state, action) {
      state.lastPlayed = action.payload;
    },
  },
});

export const { setGame, toggleGame, startGameSession, endGameSession, setSetLastPlayed } = gameSlice.actions;
export default gameSlice.reducer;