
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFriendsLoaded: false,
  friends: [],
  premium: [],
  link: '',
};

const friendsSlice = createSlice({
  name: 'friends',
  initialState,
  reducers: {
    setIsFriendsLoaded(state) {
      state.isFriendsLoaded = true;
    },
    setFriends(state, action) {
      const friends = action.payload
      state.friends = friends;
      state.premium = friends.filter(friend => friend.is_premium === true);
    },
    setLink(state, action) {
      state.link = action.payload;
    },
  },
});

export const { setFriends, setLink, setIsFriendsLoaded } = friendsSlice.actions;
export default friendsSlice.reducer;
