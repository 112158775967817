import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  id: 'game-bg-loading',
};

const bgSlice = createSlice({
  name: 'bg',
  initialState,
  reducers: {
    setBg(state, action) {
      state.loading = action.payload.loading;
      state.id = action.payload.id;
    },
  },
});

export const { setBg } = bgSlice.actions;
export default bgSlice.reducer;