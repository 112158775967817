import { useSelector } from 'react-redux';
import classes from './ScrollableList.module.css'
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const botAnim = {
  initial: { y: 20, opacity: 0 },
  animate: { 
    y: [20, 0],
    opacity: [0, 1], 
    transition: { duration: 1, ease: 'easeInOut' }
  },
};

const ScrollableList = ({ children, label, height, noBorderBot, noGap, noPad }) => {

  const isLoading = useSelector((state) => state.loading.isLoading)
  const [playAnimation, setPlayAnimation] = useState(false);

  useEffect(() => {
    if(!isLoading) {
      setPlayAnimation(true)
    }
  }, [isLoading])

  return (
    <>
    <div className={classes.title}>{label}</div>
    <motion.div className={classes.ScrollableList} onTouchMove={(e) => e.stopPropagation()} style={{height: height ? '100%' : '', borderRadius: noBorderBot ? '10px 10px 0 0' : '10px', gap: noGap ? 0 : '10px', padding: noPad ? 0 : '14px'}}
      initial="initial"
      animate={playAnimation ? "animate" : "initial"}
      variants={botAnim}
    >
      {children}
    </motion.div>
    </>
  )
}

export default ScrollableList;