import axios from "axios";
import { decode } from "./userApi";
import { path } from "./path";
import { store } from "../store/store";
import { hideError, setKey, showError } from "../store/pageSlice";

export const getTop = async (token) => {
  try {
    const response = await axios.get(
      `${path}/top_users/${decode(token)}`, 
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка получения рейтинга', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};

export const getWeekly = async (league, token) => {
  try {
    const response = await axios.get(
      `${path}/leagues/top_users_weekly/${league}/${decode(token)}`, 
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка получения рейтинга', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};