import React, { useEffect, useState } from 'react';
import Phaser from 'phaser';
import classes from './Game.module.css'
import { useDispatch } from 'react-redux';
import { endGameSession, toggleGame } from '../../store/gameSlice';

import greenDuck from './assets/dg_green_duck.png'
import redDuck from './assets/dh_red_duck.png'
import dogSprite from './assets/dh_dog.png'
import grass from './assets/dh_grass_2.png'
import tree from './assets/dh_tree_3.png'
import bush from './assets/dh_bush.png'
import scoreTile from './assets/dh_score.png'
import lives from './assets/dh_lives_2.png'
import heart from './assets/dh_heart.png'
import close from './assets/dh_close.png'
import dogEndSprite from './assets/dh_dog_end.png'

import title from './assets/dh_title.png'
import WebSocketClient from '../../api/wsApi';
import { updateBalance, updateEnergy } from '../../store/userSlice';
import { openEnergyModal } from '../../store/pageSlice';

const Game = () => {
  const [isGameOver, setIsGameOver] = useState(false)
  const [isGameLoading, setIsGameLoading] = useState(true)
  const [loadingPercent, setLoadingPercent] = useState(0)
  const [outerScore, setOuterScore] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    let interval; // Declare interval outside the if block
  
    if (isGameLoading) {
      if (loadingPercent < 100) {
        interval = setInterval(() => {
          setLoadingPercent((prev) => (prev < 100 ? prev + 1 : prev));
        }, 20);
      } else {
        setIsGameLoading(false)
      }
    }
  
    // Cleanup function to clear interval
    return () => clearInterval(interval);
  }, [isGameLoading, loadingPercent]);
  

  useEffect(() => {
    const GAME_SETTINGS = {
      DUCK_SPEED: 250,
      SPAWN_INTERVAL: 1500,
      SPEED_INCREASE_INTERVAL: 1000,
      GRAVITY: { y: 0 },
      DEBUG: false,
    };

    const getGameDimensions = () => ({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    const config = {
      type: Phaser.AUTO,
      ...getGameDimensions(),
      parent: 'game-container',
      scene: {
        preload: preload,
        create: create,
        update: !isGameLoading && update,
      },
      physics: {
        default: 'arcade',
        arcade: {
          gravity: GAME_SETTINGS.GRAVITY,
          debug: GAME_SETTINGS.DEBUG,
        },
      },
      render: {
        pixelArt: true,
        antialias: false
      }
    };

    const game = new Phaser.Game(config);
    let lastSpawnTime = 0;
    let lastSpeedIncreaseTime = 0;
    let ducks;
    let dog;
    let dogEnd;
    let score = 0;
    let livesRemain = 5;
    let hearts = [];
    let scoreText;

    function preload() {

      this.load.image('score', scoreTile);
      this.load.image('lives', lives);
      this.load.image('heart', heart);
      this.load.image('close', close);

      this.load.image('grass', grass);
      this.load.image('tree', tree);
      this.load.image('bush', bush);

      this.load.spritesheet('duck', greenDuck, { frameWidth: 35, frameHeight: 35 });
      this.load.spritesheet('redDuck', redDuck, { frameWidth: 35, frameHeight: 35 });
      this.load.spritesheet('dog', dogSprite, { frameWidth: 56, frameHeight: 56 });
      this.load.spritesheet('dogEnd', dogEndSprite, { frameWidth: 40, frameHeight: 40 });

      this.load.audio('bgMusic', '/assetsToPreload/pong.mp3');
      this.load.audio('wing', '/assetsToPreload/wing.mp3');
      this.load.audio('quack', '/assetsToPreload/quack.mp3');
      this.load.audio('shot', '/assetsToPreload/shot.mp3');
      this.load.audio('dogEndSound', '/assetsToPreload/dogEnd.mp3');
    }

    function create() {
      const background = this.add.rectangle(
        0, 0, 
        this.scale.width, this.scale.height, 
        0x63ADFF // Цвет в шестнадцатеричном формате (например, светло-голубой)
      ).setOrigin(0, 0);

      background.setScale(this.scale.width / background.width, this.scale.height / background.height);

      ducks = this.physics.add.group();
      scoreText = this.add.text(138, 12, score, { fontFamily: 'NT9', fontSize: '25px', fill: '#fff', align: 'right' });
      scoreText.setOrigin(1, 0)
      scoreText.setDepth(13)
      createAnimations.call(this);

      const scoreTile = this.add.sprite(
        10,
        10,
        'score'
      ).setOrigin(0, 0)

      scoreTile.setScale(2.5)
      scoreTile.setDepth(12)

      const lives = this.add.sprite(
        145,
        10,
        'lives'
      ).setOrigin(0, 0)

      lives.setScale(2.5)
      lives.setDepth(12)

      const grass = this.add.sprite(
        -10,
        this.scale.height,  // Почти внизу экрана
        'grass'  // Используемая текстура
      ).setOrigin(0, 1);
      
      // Масштабируем изображение, чтобы оно занимало нужную ширину и высоту
      grass.displayWidth = this.scale.width + 10;
      grass.displayHeight = this.scale.height * 0.2;
      
      grass.setDepth(10);
      
      const tree = this.add.sprite(
        30,  // Центр по X
        this.scale.height - this.scale.height * 0.16, // Почти внизу экрана, где утки будут скрываться
        'tree'  // Используемая текстура
      ).setOrigin(0, 1);  // Привязка по оси X — центр, по оси Y — верх
    
      tree.displayWidth = 120;
      tree.displayHeight = 240;

      tree.setDepth(10);

      const bush = this.add.sprite(
        this.scale.width - 90,  // Центр по X
        this.scale.height - this.scale.height * 0.16, // Почти внизу экрана, где утки будут скрываться
        'bush'  // Используемая текстура
      ).setOrigin(0, 1);  // Привязка по оси X — центр, по оси Y — верх
    
      bush.displayWidth = 60;
      bush.displayHeight = 70;

      bush.setDepth(10);

      const music = this.sound.add('bgMusic' , {
        volume: 0.8,
      });
      music.setLoop(true); // Устанавливаем зацикливание
      music.play();

    
      this.time.addEvent({
        delay: 500,
        callback: changeDirection,
        callbackScope: this,
        loop: true,
      });

      spawnDog.call(this)

      for (let i = 0; i < livesRemain; i++) {
        let heart = this.add.image(158 + i * 26, 48, 'heart'); // Отрисовываем сердечко с отступом
        heart.setScale(2)
        heart.setDepth(13)
        hearts.push(heart); // Добавляем сердечко в массив
      }

      const close = this.add.sprite(
        this.scale.width - 10,  // Позиция по X (правый верхний угол)
        10, // Позиция по Y (вверху экрана)
        'close'  // Используемая текстура
      ).setOrigin(1, 0); // Установка начала координат спрайта
      
      close.setScale(2.5);
      close.setDepth(12);
      close.setInteractive(); // Делает спрайт интерактивным
      
      close.on('pointerdown', function () {
        exitGameHandler(score);
      });

      dogEnd = this.physics.add.sprite(this.scale.width/2, this.scale.height, 'dogEnd');
      dogEnd.setOrigin(0.3, 1)
      dogEnd.setScale(2)
      dogEnd.play('dogLaugh')
    }

    function updateLives() {
      // Удаляем все старые сердечки
      hearts.forEach(heart => heart.destroy());
      hearts = [];
      
      // Отрисовываем новые сердечки по количеству оставшихся жизней
      for (let i = 0; i < livesRemain; i++) {
        let heart = this.add.image(158 + i * 26, 48, 'heart'); // Отрисовываем сердечко с отступом
        heart.setScale(2)
        heart.setDepth(13)
        hearts.push(heart);
      }
    }

    function createAnimations() {
      this.anims.create({
        key: 'duckFly',
        frames: this.anims.generateFrameNumbers('duck', { start: 1, end: 3 }),
        frameRate: 5,
        repeat: -1,
      });
      this.anims.create({
        key: 'duckHitAnim',
        frames: [{ key: 'duck', frame: 5 }],
        frameRate: 1,
      });
      this.anims.create({
        key: 'duckFall',
        frames: this.anims.generateFrameNumbers('duck', { start: 6, end: 7 }),
        frameRate: 10,
        repeat: -1,
      });

      this.anims.create({
        key: 'redDuckFly',
        frames: this.anims.generateFrameNumbers('redDuck', { start: 1, end: 3 }),
        frameRate: 5,
        repeat: -1,
      });
      

      this.anims.create({
        key: 'redDuckHitAnim',
        frames: [{ key: 'redDuck', frame: 5 }],
        frameRate: 1,
      });
      this.anims.create({
        key: 'redDuckFall',
        frames: this.anims.generateFrameNumbers('redDuck', { start: 6, end: 7 }),
        frameRate: 10,
        repeat: -1,
      });
      this.anims.create({
        key: 'dogWalk',
        frames: this.anims.generateFrameNumbers('dog', { start: 0, end: 3 }),
        frameRate: 5,
        repeat: -1,
      });
      this.anims.create({
        key: 'dogJumpUp',
        frames: [{ key: 'dog', frame: 4}],
        frameRate: 5,
      });
      this.anims.create({
        key: 'dogJumpDown',
        frames: [{ key: 'dog', frame: 5}],
        frameRate: 5,
      });

      this.anims.create({
        key: 'dogLaugh',
        frames: [
          { key: 'dogEnd', frame: 1},
          { key: 'dogEnd', frame: 0}
        ],
        frameRate: 5,
        repeat: -1,
      });
    }

    function update(time, delta) {
      if (livesRemain <= 0) {
        endGame.call(this)
        return; // Завершаем выполнение функции
      }

      if (time > lastSpawnTime + GAME_SETTINGS.SPAWN_INTERVAL) {
        spawnDuck.call(this);
        lastSpawnTime = time;
      }
    
      if (time > lastSpeedIncreaseTime + GAME_SETTINGS.SPEED_INCREASE_INTERVAL) {
        GAME_SETTINGS.DUCK_SPEED += 0.5;
        GAME_SETTINGS.SPAWN_INTERVAL -= 10;
        lastSpeedIncreaseTime = time;
      }
    
      ducks.children.iterate(duck => {
        if (!duck || !duck.body) return;
    
        if (duck.x < -50 || duck.x > this.scale.width + 50) {
          if (livesRemain > 0) {
            livesRemain--; // Уменьшаем количество жизней
            updateLives.call(this); // Обновляем отображение сердечек
          }
          duck.destroy();
        }
    
        if (duck.y < -50 || duck.y > this.scale.height + 50) {
          duck.destroy();
        }
      });
    }

    let dogSoundPlayed = false;

    function dogEndBehaviour() {
      const targetY = this.scale.height - this.scale.height * 0.17; // Конечные координаты по оси Y
      
      this.tweens.add({
          targets: dogEnd,  // Объект, который нужно перемещать (например, this.dog)
          y: targetY,         // Конечная координата Y
          ease: 'Power2',     // Плавность движения (варианты: 'Linear', 'Power1', 'Power2', 'Sine', и т.д.)
          duration: 1000,     // Длительность анимации в миллисекундах (2000 мс = 2 секунды)
          onComplete: () => {
            if (!dogSoundPlayed) {      // Проверяем, воспроизводился ли звук
              dogSoundPlayed = true;
              const sound = this.sound.add('dogEndSound');
              sound.setLoop(false); // Устанавливаем зацикливание
              sound.play();
            }
          }
      });
    }

    function endGame() {
      // Устанавливаем состояние завершения игры
      setIsGameOver(true);
      setOuterScore(score)
      game.scene.pause();
      dogEndBehaviour.call(this)
      ducks.children.iterate(duck => {
        if (duck) {
          duck.destroy();
        }
      });

      if (dog) {
        dog.setDepth(-10)
      }
    }

    function spawnDog() {
      dog = this.physics.add.sprite(-100, this.scale.height * 0.95, 'dogWalk');
      dog.setOrigin(0.5, 1);
      dog.setScale(1.5)

      let isMovingRight = true; // Флаг для направления движения
    
      function dogBehavior() {
        // Фаза 1: Собака выходит на экран и начинает ходить
        dog.setY(this.scale.height * 0.95)
        dog.setX(-100);
        dog.setVelocityX(100);
        dog.play('dogWalk');
        dog.setDepth(11);
        // Область движения собаки
        const walkAreaMin = this.scale.width * 0.2; // Левая граница
        const walkAreaMax = this.scale.width * 0.8; // Правая граница
    
        // Фаза 2: Собака прыгает через 10 секунд
        this.time.delayedCall(10000, () => {
          dog.setVelocityX(0);
          dog.play('dogJumpUp');
          dog.setVelocityY(-300);
    
          this.time.delayedCall(500, () => {
            dog.play('dogJumpDown');
            dog.setVelocityY(300);
            dog.setDepth(9);

          });

          this.time.delayedCall(1000, () => {
            dog.setVelocityY(0);
            dog.setVelocityX(isMovingRight ? 100 : -100); // Восстановим движение в правильном направлении
            dog.play('dogWalk');
          });
        });
    
        // Фаза 3: Собака скрывается и возвращается через 3 секунды
        this.time.delayedCall(12000, () => {
          this.time.delayedCall(3000, () => {
            dog.setVisible(true);
            dog.setX(-100);
            dogBehavior.call(this);
          });
        });
    
        // Таймер для движения собаки влево-вправо в определённой области
        this.time.addEvent({
          delay: 50,
          callback: () => {
            if (dog.x >= walkAreaMax) {
              dog.setVelocityX(-100); // Поворачиваем налево
              dog.setFlipX(true); // Переворачиваем спрайт
              isMovingRight = false;
            } else if (dog.x <= walkAreaMin) {
              dog.setVelocityX(100); // Поворачиваем направо
              dog.setFlipX(false);
              isMovingRight = true;
            }
          },
          loop: true,
        });
      }
    
      dogBehavior.call(this); // Запуск циклического поведения собаки
    
    }

    function spawnDuck() {
      const startX = Phaser.Math.Between(0, 1) === 0 ? -50 : this.scale.width + 50;
      const startY = Phaser.Math.Between(100, this.scale.height - this.scale.height * 0.3);
    
      const isRedDuck = Phaser.Math.Between(0, 1) === 0;
    
      const duck = ducks.create(startX, startY, isRedDuck ? 'redDuck' : 'duck');
      duck.setDisplaySize(64, 64);
      duck.play(isRedDuck ? 'redDuckFly' : 'duckFly');
      duck.setInteractive();
    
      // Флаг, чтобы отслеживать подстреленную утку
      duck.isShot = false; 
    
      const directionX = startX === -50 ? 1 : -1;
      const velocityX = directionX * Phaser.Math.Between(GAME_SETTINGS.DUCK_SPEED, GAME_SETTINGS.DUCK_SPEED + 50);
    
      duck.setVelocity(velocityX, 0);
      
      if (directionX === -1) {
        duck.setFlipX(true);
      } else {
        duck.setFlipX(false);
      }
    
      duck.on('animationupdate', (animation, frame) => {
        if ((animation.key === 'duckFly' || animation.key === 'redDuckFly') && frame.index === 1) {
          const chance = Phaser.Math.Between(0, 10) > 6;
          if (chance) {
            this.sound.play('quack');
          }
        }
      });
      // Обрабатываем событие попадания по утке
      duck.on('pointerdown', function () {
        if (!this.isShot) {
          game.sound.play('shot', {
            volume: 0.1,
          });
          this.setVelocity(0, 0);
          this.setInteractive(false); 
          this.isShot = true;
          this.play(isRedDuck ? 'redDuckHitAnim' : 'duckHitAnim'); 
          this.anims.stop();
          
          score += 10;
          scoreText.setText(score);
          
          // Задержка на 0.5 секунды перед падением
          if (this.delayedEvent) this.delayedEvent.remove();
          this.delayedEvent = this.scene.time.delayedCall(500, () => {
          if (this && this.body) {
            this.setInteractive(false);
            this.setVelocity(0, 300);
            this.play(isRedDuck ? 'redDuckFall' : 'duckFall');
            }
          }, [], this);

        }
      });
      
    }

    function changeDirection() {
      const centerY = this.scale.height / 2; // Центр экрана по оси Y
    
      ducks.children.iterate(duck => {
        if (!duck || !duck.body || duck.isShot) return; // Добавлена проверка на подстреленную утку
    
        // Логика для красной утки
        if (duck.texture.key === 'redDuck') {
          const chance = Phaser.Math.Between(0, 100);
    
          if (duck.y > centerY) {
            // Если утка ниже центра экрана
            if (chance < 30) {
              duck.setVelocityY(-100); // Подлетает вверх на 100
    
              // Таймер для сброса скорости и остановки вертикального движения
              this.time.delayedCall(1000, () => {
                if (duck && duck.body && !duck.isShot) { // Проверка на подстреленную утку
                  duck.setVelocityY(0); // Сбрасываем вертикальную скорость
                }
              });
            }
          } else {
            // Если утка выше центра экрана
            if (chance < 30) {
              duck.setVelocityY(100); // Подлетает вниз на 100
    
              // Таймер для сброса скорости и остановки вертикального движения
              this.time.delayedCall(1000, () => {
                if (duck && duck.body && !duck.isShot) { // Проверка на подстреленную утку
                  duck.setVelocityY(0); // Сбрасываем вертикальную скорость
                }
              });
            }
          }
        } else {
          // Логика для обычных уток
          if (Math.abs(duck.x - this.scale.width / 2) > 50) return;
          if (Phaser.Math.Between(0, 100) < 50) {
            duck.setVelocityX(-duck.body.velocity.x); // Меняем направление по оси X
            duck.toggleFlipX(); // Переворачиваем текстуру утки
          }
        }
      });
    }
    
    
    const resizeGame = () => {
      game.scale.resize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', resizeGame);

    return () => {
      window.removeEventListener('resize', resizeGame);
      game.destroy(true);
    };
  }, [dispatch, isGameLoading]);

  
  const exitGameHandler = (score) => {

    const handleExitResponse = (message) => {
      const msg = JSON.parse(message);
      console.log(msg);
      if (msg.status === "score_updated") {
        dispatch(updateBalance({ balance: msg.new_balance }));
        
        WebSocketClient.sendMessage(JSON.stringify({ command: "stop" }));
  
        dispatch(endGameSession());
        
        WebSocketClient.removeMessageCallback();
      }
    };
  
    WebSocketClient.sendMessage(JSON.stringify({
      command: "update_score",
      score: score,
    }));

    WebSocketClient.setOnMessageCallback(handleExitResponse);
  };


  const restartGameHandler = () => {
    
    const handleRestartResponse = (message) => {
      const msg = JSON.parse(message);
      console.log(msg);
  
      if (msg.status === "score_updated") {
        dispatch(updateBalance({ balance: msg.new_balance }));

        const handleExitResponse = (message) => {
          const exmsg = JSON.parse(message);
          console.log(exmsg);

          if (exmsg.status === "game_ended") {
          
            const handleStartGameResponse = (message) => {
              const startMsg = JSON.parse(message);
              console.log(startMsg);
            
              if (startMsg.status === 'game_started') {
                dispatch(updateEnergy({ energy: startMsg.energy }));
                setIsGameOver(false);
                setLoadingPercent(0);
                setIsGameLoading(true);
              } else {
                dispatch(endGameSession());
                dispatch(openEnergyModal());
              }
            
              WebSocketClient.removeMessageCallback(handleStartGameResponse);
            };
            
            WebSocketClient.sendMessage(JSON.stringify({
              command: "start",
              game_id: "duck_hunter"
            }));
            
            WebSocketClient.setOnMessageCallback(handleStartGameResponse);

          } else {
            dispatch(endGameSession());
          }

          WebSocketClient.removeMessageCallback(handleExitResponse);
        }

        WebSocketClient.sendMessage(JSON.stringify({ command: "stop" }));

        WebSocketClient.setOnMessageCallback(handleExitResponse);
  
      } else {
        dispatch(endGameSession());
      }
      
      WebSocketClient.removeMessageCallback(handleRestartResponse);
    };

  
    WebSocketClient.sendMessage(JSON.stringify({
      command: "update_score",
      score: outerScore,
    }));
  
    WebSocketClient.setOnMessageCallback(handleRestartResponse);
  };

  return (
    <div className={classes.gameContainer}>
      {isGameLoading && (
        <div className={classes.loading}>
          <img src={title} alt='title' className={classes.title}/>
          <div className={classes.loadingBar}>
            <div className={classes.loadingPercent} style={{width: `${loadingPercent}%`}}></div>
          </div>
        </div>
      )}
      {isGameOver && 
      <div className={classes.gameOver}>
        <div className={classes.text}>GAME OVER</div>
        <div className={classes.buttons}>
          <div className={classes.restartButton} onClick={restartGameHandler}>Restart</div>
          <div className={classes.exitButton} onClick={() => exitGameHandler(outerScore)}>Exit</div>
        </div>
      </div>
      }
      <div id="game-container"></div>
    </div>
  );
};

export default Game;