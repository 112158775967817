import axios from "axios";
import { decode } from "./userApi";
import { path } from "./path";
import { hideError, setKey, showError } from "../store/pageSlice";
import { store } from "../store/store";

export const checkTask = async (task, token) => {
  try {
    const response = await axios.post(
      `${path}/tasks/check/${task}/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка проверки задания', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};

export const checkLinkTask = async (task, token) => {
  try {
    const response = await axios.post(
      `${path}/tasks/complete_link/${task}/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка проверки задания', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};
/*
const getCountryCode = async () => {
  try {
    const response = await fetch("https://ipwhois.app/json/");
    if (!response.ok) {
      throw new Error(`Ошибка сети: ${response.status}`);
    }
    const data = await response.json();
    return data.country_code.toLowerCase(); // Например, "ru"
  } catch (error) {
    console.error("Ошибка при получении геолокации:", error);
    return null;
  }
};
*/
export const getTasks = async (token, dispatch) => {
  try {
    const url = `${path}/tasks/active/${decode(token)}`; // Если нет geo_code, запрос без параметра

    const response = await axios.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;

  } catch (err) {
    console.error('Ошибка получения заданий', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};

export const dailyReward = async (token, dispatch) => {
  try {
    const response = await axios.post(
      `${path}/users/daily_reward/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка проверки задания', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};

export const goLink = async (task, token, dispatch) => {
  try {
    const response = await axios.post(
      `${path}/tasks/go_link/${task}/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка проверки задания', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};

export const socialCheck = async (task, token, dispatch) => {
  try {
    const response = await axios.get(
      `${path}/tasks/social_check/${task}/${decode(token)}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка проверки задания', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};

export const checkSpecTask = async (task, token, dispatch) => {
  try {
    const response = await axios.post(
      `${path}/tasks/check/spec/${task}/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка проверки задания', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};