import { useDispatch, useSelector } from 'react-redux';
import classes from './TasksModal.module.css'
import { closeTasksModal, openRewardModal, setRewardValue } from '../../../store/pageSlice';
import ScrollableList from '../../ui/ScrollableList/SlrollableList';
import userImg from '../../../assets/images/main_player.png'
import dhbg from '../../../assets/images/main_dh_bg.png'
import energy from '../../../assets/images/main_energy.png'
import { updateButtonState } from '../../../store/tasksSlice';
import { checkLinkTask, checkTask } from '../../../api/taskApi';
import { updateEnergy } from '../../../store/userSlice';
import { useEffect } from 'react';

const TaskTileSmall = ({img, name, subContent, onClick, button}) => {
  
  return (
    <div className={classes.gameTile_small}>
      <div className={classes.gameLeft}>
        <img src={img} alt='userImg' className={classes.gameImg_small}/>
        <div className={classes.gameInfo_small}>
          <div className={classes.gameTitle_small}>{name}</div>
          <div className={classes.subContent}>
            +{subContent}
            <img src={energy} alt='enrg' className={classes.smallEnergy}/>
          </div>
        </div>
      </div>
      <div className={classes.playButton_small} style={{opacity: button === 'Done' ? '50%' : '100%'}} onClick={onClick}>
        {button}
      </div>
    </div>
  )
}

const TasksModal = () => {
  
  const user = useSelector((state) => state.user)

  const localTasks = useSelector((state) => state.page.currentTasks)
  const img = useSelector((state) => state.page.taskImg)
  const title = useSelector((state) => state.page.taskTitle)
  const secondTasks = useSelector((state) => state.tasks.secondTasks)

  const buttonsState = useSelector((state) => state.tasks.buttonsState)
  const token = useSelector((state) => state.page.token)

  const isTasksModalOpen = useSelector((state) => state.page.isTasksModalOpen)
  const dispatch = useDispatch()

  const closeHandler = () => {
    dispatch(closeTasksModal())
  }

  useEffect(() => {
    console.log(secondTasks.filter(task => localTasks.includes(task.id)))
  }, [])

  const commonTaskHandler = async (id) => {
    const currentState = buttonsState.find(item => item.id === id);
    const currentTask = secondTasks.find(item => item.id === id);
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    if (currentTask.task_type === 'subscribe') {
      
      //start
      if (currentState.status === 'not_completed') {
        tg.openTelegramLink(currentTask.link);
        dispatch(updateButtonState({ id: id, status: 'in_progress'}))
      }
      //check
      if (currentState.status === 'in_progress') {
        const response = await checkTask(id, token);
        if (response[0]?.status === 'completed') {
          dispatch(updateEnergy({energy: response[0].new_energy}))
          dispatch(setRewardValue(currentTask.reward))
          dispatch(openRewardModal())
          dispatch(updateButtonState({ id: id, status: 'completed'}))
        } else {
          tg.openTelegramLink(currentTask.link);
        }
      }
      //done
      if (currentState.status === 'completed') {
        tg.openTelegramLink(currentTask.link);
      }
    
    } else {
      
      if (currentState.status === 'not_completed') {
        tg.openTelegramLink(currentTask.link);
        const response = await checkLinkTask(id, token)
        if (response[0]?.status === 'completed') {
          setTimeout(() => {
            dispatch(updateEnergy({energy: response[0].new_energy}))
            dispatch(setRewardValue(currentTask.reward))
            dispatch(openRewardModal())
            dispatch(updateButtonState({ id: id, status: 'completed'}))
          }, 5000)
        }
      } else {
        tg.openTelegramLink(currentTask.link);
      }
    }
  }

  const buttonContent = (id) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим текущее состояние кнопки
  
    switch (currentState?.status) {
      case 'not_completed':
        return 'Start';
      case 'in_progress':
        return 'Check';
      case 'completed':
        return 'Done';
      default:
        return 'Unknown';
    }
  };

  if (!isTasksModalOpen) return null;

  return (
    <div className={classes.modalOverlay} onClick={closeHandler}>
      <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={classes.imageBlock} style={{ backgroundImage: `url(${img})` }}>
          <div className={classes.gradient}></div>
          <div className={classes.cross} onClick={closeHandler}>&times;</div>
          <div className={classes.text}>{title}</div>
        </div>
        <ScrollableList height={true} noBorderBot={true}>
          {secondTasks.filter(item => localTasks.includes(item.id)).map((task) => (
            <>
              <TaskTileSmall 
                img={task.image_url} 
                name={task.title} 
                subContent={task.reward} 
                onClick={() => commonTaskHandler(task.id)} 
                button={buttonContent(task.id)}
              />
              <div className={classes.hr}></div>
            </>
          ))}
        </ScrollableList>
      </div>
    </div>
  );
};

export default TasksModal;